import React, { useEffect, useState, useContext, useRef } from "react";
import { Backdrop, CircularProgress, Grid, Paper, makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useWindowDimensions from "./ComponentsDashboard/WindowDimensions";
import { UserContext } from "../../../context/UserProvider";
import { DashboardContext } from "../../../context/DashboardProvider";
import DashBoardHead from "./ComponentsDashboard/DashBoardHead";

import {
  getNewPositionsWhenTileMoved,
  requestDataFirstNodes,
  saveTilesPositions,
} from "./Functions";
import { BG_COLOR_TILES_WHEN_DRAG, BG_COLUMN_COLOR_DRAG_OFF, BG_COLUMN_COLOR_DRAG_ON, GRID } from "./constants";
import { db } from "../../../config/firebase";
import AppropriateTile from "./ComponentsDashboard/AppropriateTile";
import { MulticropHeader } from "./ComponentsDashboard/MulticropHeader";
import { MultiCropContext } from "../../../context/MultiCropContext/MultiCropContext";
import { getNewPositionFromHiddenCards, getNewPositionsWhenTileMovedTest } from "./Functions/tiles";

const useStyles = makeStyles(theme => ({
  paperWidget: {
    width: 250,
    margin: theme.spacing(1),
    position: 'relative', // Necesario para posicionar el botón de eliminar
  },
  gridItem: {
    minWidth: 250,
    maxWidth: "100%",
  },
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(1), // Ajustar el valor para controlar la distancia desde el borde superior
    right: theme.spacing(1), // Ajustar el valor para controlar la distancia desde el borde derecho
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DashBoard = () => {
  const classes = useStyles();
  const { usuario, numberOfCans, currentMac, userTimezone } = useContext(UserContext);
  const { allTiles, setAllTiles, isDragButton, setIsDragButton } = useContext(DashboardContext);
  const { tabValue, allTilesTest, setAllTilesTest, saveNewTilesPosition, hiddenCards, setHiddenCards } = useContext(MultiCropContext);
  const { username } = usuario;
  const [tilesMoved, setTilesMoved] = useState(false);
  const [isHorizontalMenuVisible, setIsHorizontalMenuVisible] = useState(false);
  const [loadingUpdateData, setLoadingUpdateData] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { width } = useWindowDimensions();
  const isSendingRef = useRef(false);

  const minutos = 120000; //60000 ms = 1 minuto

  const toggleDragMode = async () => {
    if(isHorizontalMenuVisible || tilesMoved){
      setTilesMoved(false);
      await saveNewTilesPosition();
      //await saveTilesPositions(allTiles, username);
    }
    setIsDragButton(!isDragButton);
    setIsHorizontalMenuVisible(!isHorizontalMenuVisible);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // if (!destination) return;
    // Si no hay destination o si el droppableId de destination es inválido, salir
    if (!destination || typeof destination.droppableId === "undefined") return;
    try{
      if(source.droppableId === "0" || destination.droppableId === "0") {
        const updatedHiddenTiles = getNewPositionFromHiddenCards(source,destination, allTilesTest[tabValue], hiddenCards)
        const newTabTiles = [...allTilesTest]
        const indexOfColumn = source.droppableId === "0" ? Number(destination.droppableId) : Number(source.droppableId)
        newTabTiles[tabValue] = [...updatedHiddenTiles[indexOfColumn]]
        setHiddenCards([...updatedHiddenTiles[0]]) 
        setAllTilesTest([...newTabTiles])
      } else {
        const updatedTabTiles = getNewPositionsWhenTileMoved(source,destination, allTilesTest[tabValue])
        const newTabTiles = [...allTilesTest]
        newTabTiles[tabValue] = [...updatedTabTiles]
        setAllTilesTest([...newTabTiles])
      }    
      setTilesMoved(true);
    }catch(error) {
      console.error("Error durante el drag and drop:", error)
    }
  };

  // Función para crear un retraso
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const sendMessageToFirebase = async (routeId, message) => {
    try {
      if (currentMac) {
        const addr = `${username}/infoDevices/${currentMac}/${routeId}/sendConfigModule`;
        await db.collection(addr).doc("sendConfig").set({ item: message });
      }
    } catch (error) {
      console.error('Error sending message to Firebase:', error);
    }
  };

  const sendMessages = async () => {
    for (const [index, id] of numberOfCans.entries()) {
      // Si no es el primer elemento, espera 5 segundos antes de enviar el siguiente
      if (index > 0) await delay(5000);
  
      const item = {
        action: "askingData",
        fecha: Math.floor(Date.now() / 1000),
        mac: currentMac,
        msMqtt: `2,6,${id},1`,
        uid: `${currentMac}@${id}`
      };
  
      // console.log("Enviando item:", item);
      // console.log("currentMac:", currentMac);
  
      await sendMessageToFirebase(id, item);
    }
  };

  // const sendMessages = () => {
  //   numberOfCans.forEach((id, index) => {
  //     setTimeout(() => {
  //       const item = {
  //         action: "askingData",
  //         fecha: Math.floor(Date.now() / 1000),
  //         mac: currentMac,
  //         msMqtt: `2,6,${id},1`,
  //         uid: `${currentMac}@${id}`
  //       };
  //       console.log("Esto es item:", item);
  //       console.log("Esto es currentMac:",currentMac)
  //       sendMessageToFirebase(id, item);
  //     }, 5000 * index); // Desfase de 5 segundos entre cada mensaje
  //   });
    
    
  // };

  useEffect(() => {
    if (numberOfCans.length === 0) return;

    // Si no se está enviando manualmente, se envían los mensajes inmediatamente
    if (!isSendingRef.current) {
      sendMessages();
      // console.log("Mensajes enviados inmediatamente");
    }

    // Configuramos el intervalo para enviar mensajes periódicamente
    const intervalId = setInterval(() => {
      // Antes de enviar, verificamos que no se esté ejecutando el envío manual
      if (!isSendingRef.current) {
        sendMessages();
        // console.log("Enviando mensajes periódicamente");
      }
    }, minutos); // 'minutos' debe estar en milisegundos

    // Limpiar el intervalo al desmontar o cambiar las dependencias
    return () => clearInterval(intervalId);
  }, [numberOfCans, currentMac, username, minutos]);

  useEffect(() => {
    if (!openBackdrop) return;

    const executeMessages = async () => {
      // Activamos la bandera para indicar que se está ejecutando el envío manual
      isSendingRef.current = true;
      await sendMessages(); // Se envían los mensajes de forma secuencial
      setLoadingUpdateData(false);
      setOpenBackdrop(false); // Se cierra el backdrop después de terminar
      // Desactivamos la bandera para que el intervalo pueda seguir su ejecución
      isSendingRef.current = false;
    };

    executeMessages();
  }, [openBackdrop]);
  



  return (
    <div className="container mt-5">
      <DashBoardHead isDragButton={isDragButton} onClickSaveDragged={toggleDragMode} username={username} mac={currentMac} timezone={userTimezone} />
      <MulticropHeader setOpenBackdrop={setOpenBackdrop} loadingUpdateData={loadingUpdateData} setLoadingUpdateData={setLoadingUpdateData} />
      <div >
        <DragDropContext onDragEnd={onDragEnd}>
          {isHorizontalMenuVisible && (
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{ padding: `${GRID * 2}px 0` }}>
              <Droppable droppableId={`${0}`} direction="horizontal">
                {(provided) => (
                  <Grid 
                  item 
                  ref={provided.innerRef} 
                  style={{background: BG_COLUMN_COLOR_DRAG_OFF,
                  padding: GRID,
                  overflow: "auto",
                  alignItems: "center",
                  display: "flex",
                  width: width < 650 ? "300px" : "",
                  border: width < 650 ? "2px dotted" : "",
                  minWidth:"300px",
                  minHeight:"50px"}}
                  {...provided.droppableProps} 
                  >
                    {/* {allTiles[0]?.map((item, index) => ( */}
                    {hiddenCards?.map((item, index) => (
                      <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={isDragButton}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              padding: item.kind === "ghost" ? "" : GRID * 2,
                              margin: item.kind === "ghost" ? "" : GRID * 2,
                              background: item.kind === "ghost" ? "" : "",
                              ...provided.draggableProps.style,
                              borderRadius: '15px',
                            }}
                            className={classes.paperWidget}
                            elevation={3} // Puedes ajustar el nivel de sombra, entre 0 y 24
                          >
                            <AppropriateTile item={item} index={index} col={0} />
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          )}

          <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
            {/* {console.log("Esto es allTiles:",allTiles)} */}
            {Array.from({ length: 3 }, (_, i) => i + 1).map(i => (
              <Droppable key={i} droppableId={`${i}`}>
                {(provided) => (
                  <Grid item
                  ref={provided.innerRef} 
                  style={{
                    background: BG_COLUMN_COLOR_DRAG_OFF,
                    padding: GRID,
                    border: width < 650 ? "2px dotted" : "",  //"2px dotted"
                    minWidth:"300px",
                    minHeight:"50px",
                    borderRadius: '10px',}}
                  {...provided.droppableProps} 
                  
                  >
                    {/* {console.log("allTilesTest:",allTilesTest)} */}
                    {allTilesTest[tabValue][i]?.length ?
                      allTilesTest[tabValue][i].map((item, index) => (
                      <Draggable key={item.uid} draggableId={item.uid} index={index} isDragDisabled={!isHorizontalMenuVisible}>
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              padding: item.kind === "ghost" ? "" : GRID * 2,
                              margin: item.kind === "ghost" ? "" : GRID * 2,
                              background: item.kind === "ghost" ? "" : "",
                              ...provided.draggableProps.style,
                              borderRadius: '15px',
                              
                            }}
                            className={classes.paperWidget}
                            elevation={3} // Puedes ajustar el nivel de sombra, entre 0 y 24
                          >
                            
                            <AppropriateTile item={item} index={index} col={i} />
                          </Paper>
                        )}
                      </Draggable>
                    ))
                    :
                    <div style={{ textAlign: 'center', width: '100%' }}>Cargando...</div>
                    }
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            ))}
          </Grid>
        </DragDropContext>
      </div>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export default DashBoard;

