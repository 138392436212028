import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../../context/UserProvider';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import HeadTile from './HeadTile';
import { db } from '../../../../config/firebase';
import { EcUnits } from '../../../../constants/UnitsConst';
import moment from 'moment';
import { ContainerFilling } from './ContainerFilling';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const allParameters = [
    "conductivity",
    "temperature",
    "ph",
    "humidity",
    "pressure",
    "co2",
    "nitrogen",
    "phosphorus",
    "potassium",
    "photosynthetic",
    "windSpeed",
    "rainGauge",
	"windDirection",
	"pm2.5",
	"pm10",
	"noise",
	"rainfall",
	"salinity",
	"distance",
]

const unitsForParameters = [
	"",
	"°C",
	"",
	"%",
	"kpa",
	"ppm",
	"mg/kg",
	"mg/kg",
	"mg/kg",
	"umol/m2s",
	"m/s",
	"mm",
	"",
	"ug/m3",
	"ug/m3",
	"dB",
	"mm",
	"",
	"",
]

const namesToShow = [
	"EC",
    "Temperatura",
    "ph",
    "Humedad",
    "Presión atmosférica",
    "CO2",
    "Nitrógeno",
    "Fósforo",
    "Potasio",
    "PAR",
    "Velocidad del viento",
    "Precipitación",
	"Dirección del viento",
	"Partículas PM2.5",
	"Partículas PM10",
	"Ruido ambiental",
	"Precipitación",
	"Salinidad",
	"Nivel de agua",
		
]
const soilTypes = [1,2,3];
const envTypes = [4,5,9,10,11]
const weatherType = [12];
const waterType = [13];

export const NodeLoraWanTile = ({ data, style, col }) => {
	const { usuario,unitForEC,userTimezone  } = useContext(UserContext);
	const classes = useStyles();
	const uid = data.uid;
	const arrayDeCadenas = data.uid.split("@"); //0@1@2@3@4
	const typeOfSensor = arrayDeCadenas[3]
	const [nodeName, setNodeName] = useState("Nodo");
	const [cardData, setCardData] = useState([])
	const [parameterTitles, setParameterTitles] = useState([])
	const [measurementFor, setMeasurementFor] = useState("liters")
	const [lastUpdateLocalDate, setLastUpdateLocalDate] = useState("")
	const [openAlert, setOpenAlert] = useState(false);

	// Función para formatear el valor numérico
	const formatValue = (number) => {
		// Redondear a un decimal
		const rounded = Math.round(number * 10) / 10;
		// Verificar si sigue siendo un entero después de redondear
		if (Number.isInteger(rounded)) {
		  return rounded; // Muestra sólo el entero (por ejemplo 26 en vez de 26.0)
		} else {
		  return rounded.toFixed(1); // Muestra un decimal, por ejemplo 11.9
		}
	};

	const useDocumentListener = (docPath,unitForEC,measurementFor) => {
		useEffect(() => {
		  // Obtiene la referencia al documento
		  const docRef = db.doc(docPath);
		  const ecDivider = EcUnits.find((element) => element.value === unitForEC)
		  // Se suscribe a los cambios del documento
		  const unsubscribe = docRef.onSnapshot((docSnapshot) => {
			if (docSnapshot.exists) {
			  data = docSnapshot.data();
			  const allData = data.data
			  // Crear un array de objetos que contenga el nombre y el valor juntos
			  const parameters = [];
			  for (let index = 0; index < allData.length; index++) {
				if(Number(arrayDeCadenas[3]) === allData[index].id){
					const localLastUpdate = moment(allData[index].lastUpdate).tz(userTimezone ).format("D [de] MMM, YYYY, HH:mm [Hrs.]");
					setLastUpdateLocalDate(localLastUpdate)
					for (const key in allData[index]) {
						if (allParameters.includes(key)) {
							let value = "-";
							const valueReceived = allData[index][key];
							const indexOfParameter = allParameters.indexOf(key);
							const formattedValue = formatValue(valueReceived)
							
							if(key === "humidity") { 
								value = `${valueReceived.toFixed(0)}${unitsForParameters[indexOfParameter]}`
							} else if(key === "ph"){ 
								value = formattedValue 
							} else if(key === "conductivity") {
								const valueForEc = allData[index][key]/ecDivider.value
								value = `${formatValue(valueForEc)} ${ecDivider.label}`
							} else if(key === "distance") {
								if(measurementFor === "liters") {
									const valueInLiters = allData[index]["litersWater"]
									if( valueInLiters === -1) {
										value = "-"
										setOpenAlert(true)
									} else { value = `${valueInLiters} lts.` }
								} else if(measurementFor === "percentage") {
									const valueInPercentage = allData[index]["percentageWater"]
									if(valueInPercentage === -1) {
										value = "-"
										setOpenAlert(true)
									} else { value = `${valueInPercentage}%` }
								}

							} else if(key === "windDirection") {
								const directionsString = ["Norte","Noreste","Este","Sureste","Sur","Suroeste","Oeste","Noroeste"]
								value = directionsString[valueReceived]
							} else {
								value = `${formattedValue} ${unitsForParameters[indexOfParameter]}`
							}

							// Determinar el nombre del parámetro según el tipo
							let nameOfParameter = '';
							const paramIndex = allParameters.indexOf(key);
							const nameFound = namesToShow[paramIndex];
							if (soilTypes.includes(allData[index].type)) { // Tipo soil
								nameOfParameter = `${nameFound} del sustrato`;
							} else if (envTypes.includes(allData[index].type)) { // Tipo ambientales
								nameOfParameter = `${nameFound} del ambiente`;
							} else if (weatherType.includes(allData[index].type)) { // Tipo meteorologico
								if(nameFound === "Temperatura" || nameFound === "Humedad"){
									nameOfParameter = `${nameFound} del ambiente`;
								}else {
									nameOfParameter = nameFound;
								}
							} else if (waterType.includes(allData[index].type)) { // Tipo agua
								nameOfParameter = `${nameFound} del agua`;
							} else { //MileSight or Nivel de agua
								nameOfParameter = nameFound;
							}

							// Añadir un objeto con el nombre y el valor
        					parameters.push({ name: nameOfParameter, value });
							
						}
					}
					// console.log("Esto es obtainedData:",obtainedData)
					// console.log("Esto es namesOfParameters:",namesOfParameters)
					// setCardData([...obtainedData])
					// setParameterTitles([...namesOfParameters])
					
				}
				
			  }
			  
			  // Ordenar el array de objetos alfabéticamente por el nombre
				parameters.sort((a, b) => a.name.localeCompare(b.name));

				// Extraer los datos ordenados para actualizar los estados
				const sortedNames = parameters.map(param => param.name);
				const sortedValues = parameters.map(param => param.value);

				// Actualizar el estado con los datos ordenados
				setParameterTitles(sortedNames);
				setCardData(sortedValues);
			  
			  // console.log("Esto es data:", data);
			} else {
			  console.log("¡No se encontró el documento!");
			}
		  }, (err) => {
			console.error("Error al escuchar el documento: ", err);
		  });
	  
		  // Función de limpieza que se ejecuta cuando el componente se desmonta
		  return () => unsubscribe();
	  
		}, [docPath,unitForEC,measurementFor]); // El efecto se vuelve a ejecutar si docPath cambia
	};
  
	const docPath = `${usuario.username}/loraDevices/nodes/${arrayDeCadenas[2]}/sensors/dataSensors`;
  
	useDocumentListener(docPath,unitForEC,measurementFor);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true; // Indicar que el componente está montado
		const obtenerDatos = async () => {
			try {
			  const arrayDeCadenas = uid.split("@");
			  const nodeid = arrayDeCadenas[2];
			  const addr2 = `${usuario.username}/loraDevices/nodes/${nodeid}/namesOfCards`;

			  const leerConfigNode = async () => {
				try {
				  const docRef = db.collection(addr2).doc("names");
				  const docSnap = await docRef.get();
			  
				  if (docSnap.exists) {
					const allSensorNames = docSnap.data().allNames;
					const idSensor = uid.split("@")[3];
					for (let index = 0; index < allSensorNames.length; index++) {
						if(Number(idSensor) === allSensorNames[index].id ) {
							// if(soilTypes.includes(allSensorNames[index].type)) {
							// 	assignName = `${allSensorNames[index].name}-Soil`
							// } else if(envTypes.includes(allSensorNames[index].type)){
							// 	assignName = `${allSensorNames[index].name}-Amb.`
							// } else {
							// 	assignName = `${allSensorNames[index].name}`
							// }
							
							setNodeName(allSensorNames[index].name)
							
						}
					}
					// Solo actualizar si el componente aún está montado
					if (isMountedRef.current) {
						
					}
					
				  } else {
					// El documento no existe.
					if (isMountedRef.current) {
					  console.log("No se encontró nombre del Nodo Lora");
					}
					
				  }
	
				} catch (error) {
				  console.error("Error al obtener el documento:", error);
				}
			  };
			  
			  //leerDatosDB();
			  leerConfigNode();
			} catch (error) {
			  console.log("obtenerDatos: error ", error);
			}
		};
		obtenerDatos();
		return () => {
		isMountedRef.current = false; // Indicar que el componente se ha desmontado
		};

	}, [uid, usuario.username])

	useEffect(() => {
		
		const obtainWaterContainerData = async () => {
		  try {
			const docPath = `${usuario.username}/loraDevices/nodes/${arrayDeCadenas[2]}/waterContainers`;
			const docRef = db.collection(docPath).doc("waterTankData");
			const getData = await docRef.get();
	  
			if (getData.exists) {
			  const containerData = getData.data().data;
			  setMeasurementFor(containerData.measurementBy);
			}
		  } catch (error) {
			console.error("Error al obtener datos del contenedor:", error);
		  }
		};
		
		// Llamamos a la función para cargar datos
		obtainWaterContainerData();
	  
	  }, [usuario.username,arrayDeCadenas]);

  return (
	<Grid
	container
	direction="row"
	justifyContent="center"
	alignItems="center"  // Cambiado a "center" para centrar verticalmente.
	style={style}
	>
  	<HeadTile name={nodeName} uid={uid} col={col} lastUpdate={lastUpdateLocalDate} />
	{openAlert && (
		<Alert severity="warning">Datos del contenedor de agua incompletos</Alert>
	)}
	
  {(cardData.length !== 0 && (measurementFor === "liters" || (typeOfSensor !== "14" && typeOfSensor !== "15")) ) &&
    cardData.map((value, index) => (
      <React.Fragment key={index}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="center"  // Alineación centrada de los elementos hijos.
          justifyContent="center"
          className="parameter-grid"  // Clase CSS personalizada para aplicar estilos.
        >
          <Typography variant="subtitle2" gutterBottom>
            {parameterTitles[index]}
          </Typography>
          <h5>
            <span className="badge badge-dark value-badge">{value}</span>
          </h5>
        </Grid>
        {(index === 1 || index === 3 || index === 5) && (
          <Grid item xs={11}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </React.Fragment>
    ))}
	
	{(cardData.length !== 0 && (typeOfSensor === "15" || typeOfSensor === "14")) && measurementFor === "percentage" && (
		<ContainerFilling percentageValue={cardData} />
	)}
</Grid>

  )
}
