import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TransitionAlertCommand from "./TransitionAlertCommand";
import PopupForm from "./PopupForm";
import { Alert, AlertTitle } from "@material-ui/lab";
import { db } from "../../../../config/firebase";
import { Eco } from "@material-ui/icons";
import { MultiCropContext } from "../../../../context/MultiCropContext/MultiCropContext";
import moment from "moment";

const DashBoardHead = ({ isDragButton, onClickSaveDragged, username, mac, timezone }) => {
  const { hiddenCards, horizontalMenuFlag } = useContext(MultiCropContext)
  const [latestModulesUpdate, setLatestModulesUpdate] = useState("")

  const IconDragColumn=()=>{
      if(isDragButton) return <LockIcon />
      return <LockOpenIcon/>
  }

  const [deviceOffline, setDeviceOffline] = useState(false);
  const [deviceOnline, setDeviceOnline] = useState(false);

  useEffect(() => {
    if(mac !== "") { 
      const docPath = `${username}/infoDevices/${mac}/1/stateModule/state`;
      const renderDoc = db.doc(docPath);
      // Verificar si el documento existe
      renderDoc.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          // Si el documento existe, suscribirse a las actualizaciones en tiempo real
          const unsubscribe = renderDoc.onSnapshot((doc) => {
            const data = doc.data();
            const stateDevice = data.isOnline;
            setDeviceOnline(stateDevice);
            setDeviceOffline(!stateDevice);
          });

          // Limpiar la suscripción cuando el componente se desmonte
          return () => {
            unsubscribe();
          };
        } else {
          console.log('No existe la ruta del documento de alertas.');

        }
      }).catch((error) => {
        console.error('Error obteniendo el documento:', error);
      });
    }
    
  }, [username, mac]);

  useEffect(() => {
    if(mac !== ""){
      const docPath = `${username}/infoDevices/${mac}`
      // Referencia al documento que deseas escuchar (ajusta 'coleccion' y 'documentoId' según tu estructura)
      const docRef = db.collection(docPath).doc('1');

      // Escuchamos en tiempo real los cambios del documento
      const unsubscribe = docRef.onSnapshot(doc => {
        if (doc.exists) {
          const dataObtained = doc.data();
          if ('lastUpdate' in dataObtained) {
            const localDate = moment(dataObtained.lastUpdate).tz(timezone).format("D/MM/YYYY, HH:mm [Hrs.]");
            setLatestModulesUpdate(localDate)
          } else {
            console.log("El parámetro 'lastUpdate' no existe en el documento");
          }
          
        } else {
          console.log('El documento no existe');
        }
      }, error => {
        console.error('Error al obtener el documento:', error);
      });

      // Limpiamos el listener al desmontar el componente
      return () => unsubscribe();
    }
  }, [username,mac,timezone])
  
  

  return (
    <>
    {/* <Grid container justifyContent="flex-end" alignItems="flex-end">
      
      <Grid item>
      <Eco color={deviceOffline ? "secondary" : "primary"} fontSize="large"  />
      {deviceOffline ? "Offline" : "Online"}
      </Grid>
    </Grid> */}

    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item xs={3}>
        <Tooltip title="Agregar/Mover Cards" arrow>
          <span> 
            <Button
            variant="contained"
            color="primary"
            startIcon={<IconDragColumn />}
            onClick={onClickSaveDragged}
            disabled={!horizontalMenuFlag}
            />
          </span>
        </Tooltip>
        
      </Grid>

      <Grid item xs={3}>
        {/* <label>{timezone}</label> */}
      </Grid>

      {/* <Grid item xs={3} />       */}
      <Grid item xs={3}>
        <Eco color={deviceOffline ? "secondary" : "primary"} fontSize="large"  />
        {deviceOffline ? "Offline" : "Online"}
      </Grid>
      {/* <Grid item xs={3}>
        <Typography variant="subtitle1">
          Última actualización:
          {latestModulesUpdate}
        </Typography>
      </Grid> */}

      <Grid item xs={3}>
        <PopupForm />
      </Grid>
      
      {/* <Grid item xs={12}>
        <TransitionAlertCommand />
      </Grid> */}
      
    </Grid>
    <div style={{ height: "50px" }}>
      <TransitionAlertCommand />
    </div>
  
    {deviceOffline &&
      <Grid container justifyContent="center" alignContent="center">
      <Alert severity="error">
        <AlertTitle>Gapy Master desconectado</AlertTitle>
        Gappier tu módulo principal se encuentra <strong>!Desconectado!</strong> revisalo.
      </Alert>
    </Grid>
    }
    {/* <PopupForm /> */}
    
    </>
  );
};

export default DashBoardHead;

