import React, { useContext, useState } from 'react'
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, withStyles } from '@material-ui/core'
import { green, grey } from '@material-ui/core/colors';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
      '&$disabled': {
        color: grey[500],
      },
    },
    checked: {},
    disabled: {},
  })((props) => <Checkbox color="default" {...props} />);

  const parameterNames = ["DPV (Déficit de Presión de Vapor)","DLI (Daily Light Integral)"]

export const ParametersToCalculateForm = () => {
	const {parametersDisplayStatus,tabValue} = useContext(MultiCropContext)
	const [parametersState, setParametersState] = useState(Array(2).fill(false));

	const handleParameterSelected = (index) => {
		const newParameterState = [...parametersState];
		newParameterState[index] = !newParameterState[index];
		//setZona(newZone);
		setParametersState(newParameterState)
	};

  return (
	<div>
		<div style={{textAlign:"center",justifyContent:'center',alignItems:'center', marginBottom:"20px", marginTop:"20px"}}>
			<h5>Parametros a mostrar</h5>
		</div>
		
		<Grid 
		container 
		justifyContent="center"
		alignItems="center">
			<FormControl>
				{/* <FormLabel>Indicadores Dinámicos de Cultivo</FormLabel> */}
				<FormGroup>
					{parameterNames.map((name,index) => (
						<FormControlLabel
						key={index}
						control={
							<GreenCheckbox  checked={parametersDisplayStatus[tabValue].includes(name)} 
							onChange={() => handleParameterSelected(index)} 
							/>}
						label={name}
						/>
					))}
					
				</FormGroup>
			</FormControl>
		</Grid>

		<Grid 
		container 
		justifyContent="center"
		alignItems="center">

			<Button variant="contained" color="primary">
				Aplicar cambios
			</Button>
		</Grid>
		
		
		
	</div>
  )
}
