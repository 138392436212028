import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import SwitchT from "./SwitchT";
import HeadTile from "./HeadTile";
import { UserContext } from "../../../../context/UserProvider";
import { db } from "../../../../config/firebase";
import { get_Uid_Sate_ForSwitch } from "../../../../context/functions/DashboardFunctions/divideUIDByKind";

const DiscreteSensorTile = ({
  col,
  data,
  onClickCrossButton,
  style,
}) => {
  const { name, uid } = data;
  const [state, setState] = useState("0");
  const { usuario, dataMacCan,numberOfCans,typeOfCans } = useContext(UserContext);
  const { username } = usuario;
  
  useEffect(() => {
    const canId = String(uid.split("@")[1]);
    const mac = String(uid.split("@")[0]);
    const kind = String(uid.split("@")[2]);
    const outid = String(uid.split("@")[3]);
    // const idxMac = dataMacCan.findIndex((elmt) => {
    //   return String(elmt.mac) === String(mac);
    // });
    // const idxCan = dataMacCan[idxMac].cans.findIndex((elmt) => {
    //   return String(elmt.id) === String(canId);
    // });
    const docPath = `${username}/infoDevices/${mac}/${canId}/fromModule/render`;
    const renderDoc = db.doc(docPath);
    const indexOfCan = numberOfCans.indexOf(canId)
    const typeOfModule = typeOfCans[indexOfCan]
    
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      const resp = get_Uid_Sate_ForSwitch({
        fromModuleActual: data,
        kind: kind,
        outid: outid,
        typeOfModule: typeOfModule,
        // typeOfModule: dataMacCan[idxMac].cans[idxCan].type,
      });
       
      if (resp.state !== state) {
        setState(resp.state);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [usuario, uid,state,username,numberOfCans,typeOfCans]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
      <HeadTile
        name={name}
        uid={uid}
        onClickCrossButton={onClickCrossButton}
        col={col}
      />

      <Grid item>
        <SwitchT isOn={String(parseInt(state))} id={uid} />
      </Grid>
    </Grid>
  );
};

export default React.memo(DiscreteSensorTile,(prevProps,newProps)=>{
  return prevProps.data.uid === newProps.data.uid
});
