import { db } from "../../../../config/firebase";

export const ACTION_MQTT_WHEN_TOGGLE = "sendToggle";
export const ACTION_MQTT_WHEN_ASK = "askingData";
export const ACTION_MQTT_WHEN_PUMBSHELLY = "activateShelly";
const ACTION_DESCRIPTION_NUMBER = {};
ACTION_DESCRIPTION_NUMBER[ACTION_MQTT_WHEN_ASK] = "6";
ACTION_DESCRIPTION_NUMBER[ACTION_MQTT_WHEN_TOGGLE] = "4";
ACTION_DESCRIPTION_NUMBER[ACTION_MQTT_WHEN_PUMBSHELLY] = "100";

const requestDataIncreasedNode = async ({
  dataMacCan,
  dataMacCanDash,
  username,
}) => {
  await Promise.all(
    dataMacCan.map(async (node, idx) => {
      const { mac } = node;
      const nodeId = node.cans[dataMacCanDash[idx].cnt].id;

      const anotherJustToFill = "1";
      const messageNode = [nodeId, anotherJustToFill];
      const actionDescription = ACTION_MQTT_WHEN_ASK;
      const uid = `${mac}@${nodeId}`;
      console.log({
        msg: "requestDataIncreasedNode",
        mac,
        username,
        nodeId,
        messageNode,
        actionDescription,
        uid,
      });
      await saveRequestDataToNodeAddr(
        mac,
        username,
        nodeId,
        messageNode,
        actionDescription,
        uid
      );
    })
  );
};

const requestDataFirstNodes = async (dataMacCan, username) => {
  await Promise.all(
    dataMacCan.map(async (node) => {
      const { mac } = node;
      const nodeId = node.cans[0].id;
      const anotherJustToFill = "1";
      const messageNode = [nodeId, anotherJustToFill];
      const actionDescription = ACTION_MQTT_WHEN_ASK;
      const uid = `${mac}@${nodeId}`;
      await saveRequestDataToNodeAddr(
        mac,
        username,
        nodeId,
        messageNode,
        actionDescription,
        uid
      );
    })
  );
};

const sendCommandWhenToggle = async (username, toggle) => {
  const { canid, outid, posicion, mac, uid } = toggle;
  const isFromLocalPage = "0";
  let messageNode = [canid, outid, posicion, isFromLocalPage];
  let actionDescription = ACTION_MQTT_WHEN_TOGGLE;
  // if (Number(outid) === 9) {
  //   messageNode = ["0", posicion];
  //   actionDescription = ACTION_MQTT_WHEN_PUMBSHELLY;
  // }
  await saveRequestDataToNodeAddr(
    mac,
    username,
    canid,
    messageNode,
    actionDescription,
    uid
  );
};

const saveRequestDataToNodeAddr = async (
  mac,
  username,
  nodeId,
  messageNode,
  actionDescription,
  uid
) => {
  const addr = `${username}/infoDevices/${mac}/${nodeId}/sendConfigModule`;
  const descriptionMQTT = getObjectDescriptionForMessageMQTT(
    mac,
    actionDescription,
    messageNode,
    uid
  );
  if (!descriptionMQTT) {
    console.error("convertActionDescriptionToActionNumber is not found");
    return;
  }

  try {
    await db.collection(addr).doc("sendConfig").set({ item: descriptionMQTT }); //len,accion,can,isOnline
  } catch (error) {
    console.log(error);
  }
};

const getObjectDescriptionForMessageMQTT = (
  mac,
  actionDescription,
  messageNode,
  uid
) => {
  const msMqtt = getMessageCodifyForMQTT(actionDescription, messageNode);
  const isNullMs = !msMqtt;
  if (isNullMs) {
    return null;
  }
  return {
    msMqtt,
    mac,
    action: actionDescription,
    fecha: Date.now(),
    uid,
  };
};

const getMessageCodifyForMQTT = (actionDescription, messageNode) => {
  const lenMessageNode = String(messageNode.length);
  const actionNumber = ACTION_DESCRIPTION_NUMBER[actionDescription];

  const messageMQTT = [lenMessageNode, actionNumber, ...messageNode];

  const isNullActionNumber = !actionNumber;
  if (isNullActionNumber) {
    return null;
  }
  return messageMQTT.join(",");
};

export {
  requestDataFirstNodes,
  requestDataIncreasedNode,
  sendCommandWhenToggle,
};
