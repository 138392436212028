import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Switch, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		position: 'relative',
		width: "30%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "80%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	notificationStartText: {
		color: grey[600]
	}
}));
  
export const NotificationsSettings = ({ selectedNotifications,changeNotificationSelection,emailUser,setNewEmail,
	changeNotificationFlag, invalidEmail, newStateInvalidEmail,userWeatherPhone,setUserWeatherPhone,invalidWeatherPhonenumber,
	setInvalidWeatherPhonenumber
 }) => {
  const classes = useStyles();
  const [statesSwitches, setStatesSwitches] = useState({
	email: false,
	weatherPhoneNumber: false
  });
  const [emailForNotification, setEmailForNotification] = useState("")
//   const [error, setError] = useState(false);
  const [helperTextForEmail, setHelperTextForEmail] = useState('')
  const [phonenumberForWeatherWarnings, setPhonenumberForWeatherWarnings] = useState('')

  const handleChangeSwitch = (event) => {
	setStatesSwitches({...statesSwitches, [event.target.name]: event.target.checked});
	changeNotificationFlag(true);
	const newArrayOfNotifications = [...selectedNotifications];
	if(event.target.checked){
		//Agregar
		if(event.target.name === "email") {
			newArrayOfNotifications.push("email")
		} else if(event.target.name === "weatherPhoneNumber") {
			newArrayOfNotifications.push("weatherPhoneNumber")
		}
		changeNotificationSelection([...newArrayOfNotifications]);
	} else{
		if(invalidEmail) {
			setEmailForNotification("")
		}
		//Eliminar
		const elementIndex = newArrayOfNotifications.indexOf(String(event.target.name));
		newArrayOfNotifications.splice(elementIndex,1);
		changeNotificationSelection([...newArrayOfNotifications]);
	}
  };

  const handleChangeEmail = (event) => {
	const { value } = event.target;
	setEmailForNotification(value);
	
	// Simple regex for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	if (emailRegex.test(value)) {
		newStateInvalidEmail(false);
		setHelperTextForEmail('');
		//Guardar el nuevo Email
		setNewEmail(value);
		changeNotificationFlag(true);
	  } else {
		newStateInvalidEmail(true);
		setHelperTextForEmail('Por favor ingrese un correo valido');
	  }
  }

  const handleChangeWeatherPhonenumber = (event) => {
    const value = event.target.value;
    // Permitir solo dígitos
    if (/^\d*$/.test(value)) {
      setPhonenumberForWeatherWarnings(value);
      // Validar que el número tenga 10 dígitos
      if (value.length === 10) {
        setInvalidWeatherPhonenumber(false);
		setUserWeatherPhone(value)
		changeNotificationFlag(true);
      } else {
        setInvalidWeatherPhonenumber(true);
      }
    }
  };

  useEffect(() => {
	if(selectedNotifications.length > 0){
		const newStates = {email: false,}
		for (let index = 0; index < selectedNotifications.length; index++) {
			if(selectedNotifications[index] === "email") {
				newStates.email = true
			} else if(selectedNotifications[index] === "weatherPhoneNumber") {
				newStates.weatherPhoneNumber = true
			}
		}
		setStatesSwitches({...newStates})
	}
	if(emailUser !== "") {
		setEmailForNotification(emailUser)
	}
	if(userWeatherPhone !== "") {
		setPhonenumberForWeatherWarnings(userWeatherPhone)
	}
	
  }, [selectedNotifications,emailUser,userWeatherPhone])

  useEffect(() => {
	if(!statesSwitches.email) {
		newStateInvalidEmail(false);
		setHelperTextForEmail('')
	}
  }, [statesSwitches])
  
  return (
	<div>
		<Typography variant="body1" className={classes.notificationStartText}>
			* En esta sección, puedes habilitar los diferentes tipos de notificaciones que deseas recibir.
		</Typography>
		<div>
			<label className={"mt-3"}>Reglas avanzadas por correo electronico</label>
			<Switch
			checked={statesSwitches.email}
			onChange={handleChangeSwitch}
			color="primary"
			name="email"
			/>
		</div>
		<Grid container justifyContent='flex-start'>
			<label className={"mt-3"}>Email:</label>
			<TextField 
			id="email-address" 
			variant="outlined" 
			size="small"
			margin="dense"
			label="Correo"
			type="email"
			value={emailForNotification}
			onChange={handleChangeEmail}
			error={invalidEmail}
			helperText={helperTextForEmail}
			className={classes.textField}
			disabled={!statesSwitches.email}
			/>
		</Grid>
		
		<div>
			<label className={"mt-3"}>Datos meteorológicos por WhatsApp</label>
			<Switch
			checked={statesSwitches.weatherPhoneNumber}
			onChange={handleChangeSwitch}
			color="primary"
			name="weatherPhoneNumber"
			/>
		</div>
		<Grid container justifyContent="flex-start">
        <label className={"mt-3"}>WhatsApp:</label>
        <TextField
          id="weatherPhonenumber"
          variant="outlined"
          size="small"
          margin="dense"
          label="Número"
          value={phonenumberForWeatherWarnings}
          onChange={handleChangeWeatherPhonenumber}
          error={invalidWeatherPhonenumber}
          helperText={invalidWeatherPhonenumber ? "El número debe tener 10 dígitos" : ""}
          className={classes.textField}
          inputProps={{ maxLength: 10 }} // Limita la entrada a 10 dígitos
		  disabled={!statesSwitches.weatherPhoneNumber}
        />
      </Grid>
  	</div>
  )
}
