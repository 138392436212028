import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { db } from '../../../../../config/firebase';
const waterTankIcon = "waterTank"

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "37%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
  }));


export const ConfigWaterLevel = forwardRef((props, ref) => {
	const classes = useStyles();
	const [waterTankHeight, setWaterTankHeight] = useState("")
	const [waterTankDiameter, setwaterTankDiameter] = useState("")
	const [totalWaterTankCapacity, setTotalWaterTankCapacity] = useState("")
	const [measuringMode, setMeasuringMode] = useState("percentage")
	const [sensorToLiquidDistance, setSensorToLiquidDistance] = useState("")

	const handleChangeHeight = (event) => {
		if(event.target.value >= 0){
			setWaterTankHeight(event.target.value)
		}
	}

	const handleChangeDiameter = (event) => {
		if(event.target.value >= 0){
			setwaterTankDiameter(event.target.value)
		}
	}

	const handleChangeTotalCapacity = (event) => {
		if(event.target.value >= 0){
			setTotalWaterTankCapacity(event.target.value)
		}
		
	}

	const handleChangeMode = (event) => {
		setMeasuringMode(event.target.value)
	}

	const handleChangeSensorToLiquidDistance = (event) => {
		if(event.target.value >= 0){
			setSensorToLiquidDistance(event.target.value)
		}
	}

	// useImperativeHandle nos permite exponer ciertas funciones o valores
	// al componente padre a través de la 'ref'.
	useImperativeHandle(ref, () => ({
    // Esta función será invocada desde el componente padre
		saveContainerData: async () => {
		try {
			// Aquí colocas tu lógica para guardar datos en la ruta de Firebase que corresponde al hijo
			const docPath = `${props.username}/loraDevices/nodes/${props.nodeId}/waterContainers`;
			const docRef = db.collection(docPath).doc("waterTankData");
			const diameterNum = Number(waterTankDiameter)
			// Radio del cilindro (m)
			const radio = diameterNum / 2;
			// Área de la base del cilindro (m²)
			const area = Math.PI * Math.pow(radio, 2);
			const distanceFromSensorToLiquidinMet = props.typeSensor === "15" ? Number(sensorToLiquidDistance) : 0;
			const data = {
				height: Number(waterTankHeight),
				diameter: Number(waterTankDiameter),
				capacity: Number(totalWaterTankCapacity),
				measurementBy: measuringMode,
				area: area,
				sensorToLiquidDistance: distanceFromSensorToLiquidinMet

			}
			await docRef.set({data})
			// Ejemplo (si usas Firestore):
			// await firebase.firestore().collection("rutaDelHijo").add({ data: childData });

		} catch (error) {
			console.error("Error guardando datos del hijo:", error);
		}
		}
	}));

	useEffect(() => {
	  let isMounted = true; // Bandera para verificar si el componente está montado
		
		const obtenerDatos = async () => {
			try {	
			const addr = `${props.username}/loraDevices/nodes/${props.nodeId}/waterContainers`;
	
			const leerDatosDB = async () => {
				try {
				const docRef = db.collection(addr).doc("waterTankData");
				const docSnap = await docRef.get();
	
				if (docSnap.exists) {
					const dataItem = docSnap.data().data;	
					if (isMounted) {
						setWaterTankHeight(dataItem.height);
						setwaterTankDiameter(dataItem.diameter)
						setTotalWaterTankCapacity(dataItem.capacity)
						setMeasuringMode(dataItem.measurementBy)
						setSensorToLiquidDistance(dataItem.sensorToLiquidDistance)

					}
				} else {
					console.log("No se encontró el documento!");
				}
				} catch (error) {
				console.error("Error al obtener el documento:", error);
				}
			};
	
			await leerDatosDB();
			} catch (error) {
			console.error("Error en obtenerDatos:", error);
			}
		};
	
		obtenerDatos();
	
		return () => {
			isMounted = false; // Marca como desmontado en la limpieza
		};
	}, [props.username,props.nodeId])
	
	
  return (
	
	<Grid container direction="column" spacing={3} alignItems="center">
		<div style={{ marginBottom: '10px', textAlign:'center', marginTop:'10px', fontWeight: 'bold', fontSize: '24px'}}>
			Ajuste de Parámetros de Detección de Nivel
		</div>
		<div style={{marginTop: "10px",marginBottom:"5px",textAlign:'center'}}>
			<h5>Datos del contenedor cilindrico</h5>
		</div>
		{/* Altura del contenedor */}
		<Grid item container spacing={2} alignItems="center" justifyContent="flex-start">
			<Grid item xs={12} sm={4} justifyContent="flex-start">
				<Typography variant="body1">Altura del contenedor:</Typography>
			</Grid>
			<Grid item xs={10} sm="auto">
				<TextField
					id="height"
					type="number"
					variant="outlined"
					size="small"
					value={waterTankHeight}
					onChange={handleChangeHeight}
				/>
			</Grid>
			<Grid item xs={2} sm="auto">
			<Typography variant="body2">metros</Typography>
			</Grid>
		</Grid>
		{/* Diámetro del contenedor */}
		<Grid item container spacing={2} alignItems="center" justifyContent="flex-start">
			<Grid item xs={12} sm={4} justifyContent="flex-start">
			<Typography variant="body1">Diámetro del contenedor:</Typography>
			</Grid>
			<Grid item xs={10} sm="auto">
			<TextField
				id="diameter"
				type="number"
				variant="outlined"
				size="small"
				value={waterTankDiameter}
				onChange={handleChangeDiameter}
			/>
			</Grid>
			<Grid item xs={2} sm="auto">
			<Typography variant="body2">metros</Typography>
			</Grid>
		</Grid>

		{props.typeSensor === "15" && (
			<Grid item container direction='row' spacing={2} alignItems="center" >
			<Grid item xs={12} sm={4} justifyContent="flex-start">
				<Typography variant="body1">
				Separación entre el sensor y el punto de llenado máximo:
				</Typography>
			</Grid>
			<Grid item xs={10} sm="auto" justifyContent="center">
				<TextField
				id="distance_sensor"
				type="number"
				variant="outlined"
				size="small"
				value={sensorToLiquidDistance}
				onChange={handleChangeSensorToLiquidDistance}
				/>
			</Grid>
			<Grid item xs={2} sm="auto" justifyContent="flex-end">
				<Typography variant="body2">metros</Typography>
			</Grid>
			</Grid>
		)}

		{/* Capacidad total del contenedor */}
		<Grid item container spacing={2} alignItems="center" justifyContent="flex-start">
			<Grid item xs={12} sm={4} justifyContent="flex-start">
			<Typography variant="body1">Capacidad total del contenedor:</Typography>
			</Grid>
			<Grid item xs={10} sm="auto">
			<TextField
				id="max_capacity"
				type="number"
				variant="outlined"
				size="small"
				value={totalWaterTankCapacity}
				onChange={handleChangeTotalCapacity}
			/>
			</Grid>
			<Grid item xs={2} sm="auto">
			<Typography variant="body2">litros</Typography>
			</Grid>
		</Grid>

		{/* Modo de medición */}
		<Grid item container spacing={2} alignItems="center" justifyContent="flex-start">
			<Grid item xs={12} sm={4} justifyContent="flex-start">
				<Typography variant="body1" >
					Modo de medición:
				</Typography>
			</Grid>
			<Grid item xs={12} sm={8} justifyContent="flex-start">
			<FormControl variant="outlined" size="small" className={classes.formControl}>
				<InputLabel id="demo-simple-select-outlined-label">Selecciona</InputLabel>
				<Select
				labelId="demo-simple-select-outlined-label"
				id="demo-simple-select-outlined"
				value={measuringMode}
				onChange={handleChangeMode}
				label="Selecciona"
				>
					<MenuItem value={"percentage"}>Porcentaje</MenuItem>
					<MenuItem value={"liters"}>Litros</MenuItem>
				</Select>
			</FormControl>
			</Grid>
			
		</Grid>		
		
		{/* <div>
			<label style={{ marginRight: "5px", marginTop:"5px"}}>Modo de medición:</label>
			<FormControl variant="outlined" className={classes.formControl}>
				<Select
				labelId="demo-simple-select-outlined-label"
				id="demo-simple-select-outlined"
				value={measuringMode}
				onChange={handleChangeMode}
				// label="Age"
				>
					<MenuItem value={"percentage"}>Porcentaje</MenuItem>
					<MenuItem value={"liters"}>Litros</MenuItem>
				</Select>
			</FormControl>
		</div> */}
		
	</Grid>
	
  )
})
