import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from "react";
import { UserContext } from "../../../../context/UserProvider";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper } from "@material-ui/core";
import HeadTile from "./HeadTile";
import { HIGHCHART_OPT_SPANISH, continuosSensors } from "../../../../constants/globalConst";
import { db } from "../../../../config/firebase";
import {
  get_Uid_Value_ForAnalog,
  getDataFromDB,
} from "../../../../context/functions/DashboardFunctions/divideUIDByKind";
import { DashboardContext } from "../../../../context/DashboardProvider";
import { EcUnits } from "../../../../constants/UnitsConst";

const useStyles = makeStyles({
  paperGraphics: {
    // width: "215px",
    //height: "170px",
    //padding: "5px",
    display: "flex",
    alignItems: "center",
    justifycontent: "center",
    background: "transparent",
  },
  minmaxText: {
    color: "#ACACAC",
    fontSize: "8px",
  },
  noGraphText: {
    color: "#ACACAC",
  },
  titleGraph: {
    color: "#000",
    fontSize: "30px",
  },
  subtitleGraph: {
    color: "#5BB033",
    fontSize: "20px",
  },
});

const TestContinousSensorTile = ({
  col,
  data,
  style,
}) => {
  const { usuario, dataMacCan, getTimezoneOffsetInSeconds,unitForEC,currentMac,typeOfCans,numberOfCans } = useContext(UserContext);
  const classes = useStyles();
  const [minVal, setMinVal] = useState(Infinity);
  const [maxVal, setMaxVal] = useState(-Infinity);
  const [valArrayChart, setValArrayChart] = useState([]);
  const { name, uid } = data;
  const [value, setValue] = useState(null);
  const [prevValue, setPrevValue] = useState(null);
  const [isDataFlag, setIsDataFlag] = useState(false)
  const [headName, setHeadName] = useState(data.name)
  const prevValueRef = useRef(0);
  
  useEffect(() => {
    Highcharts.setOptions(HIGHCHART_OPT_SPANISH);

    const fetchAllData = async () => {
        try {
            const results = await getDataFromDB(uid, usuario.username);
            setValArrayChart([...results.valArray]);
            setMinVal(results.minVal);
            setMaxVal(results.maxVal);
        } catch (err) {
            console.error({ err });
            setValArrayChart([]);
            setMinVal(Infinity);
            setMaxVal(-Infinity);
        }
    };

    fetchAllData();
}, [uid, usuario.username]);

const updateChartData = useCallback((newValue) => {
  let newData = [...valArrayChart, [Math.floor(Date.now()), newValue]];
  const limit = 20;
  if (newData.length > limit) {
      newData = newData.slice(newData.length - limit);
  }
  setValArrayChart(newData);
}, [valArrayChart]);

useEffect(() => {
  if(currentMac !== "") {
    const indexOfCan = numberOfCans.indexOf(uid.split("@")[1]);
    const typeOfModule = typeOfCans[indexOfCan]
    const docPath = `${usuario.username}/infoDevices/${uid.split("@")[0]}/${uid.split("@")[1]}/fromModule/render`;
    const renderDoc = db.doc(docPath);

    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
        const data = docSnapshot.data();
        const resp = get_Uid_Value_ForAnalog({
            fromModuleActual: data,
            kind: uid.split("@")[2],
            outid: uid.split("@")[3],
            typeOfModule: typeOfModule,
            // typeOfModule: dataMacCan.find((elmt) => elmt.mac === uid.split("@")[0]).cans.find((elmt) => elmt.id === uid.split("@")[1]).type,
        });

        if(resp.value === "-1.0") {
          setIsDataFlag(true);
          setPrevValue(resp.value);
        }else if (resp.value !== prevValue && resp.value >= 0.1) {
          setIsDataFlag(false);
          if(uid.split("@")[2] === "8"){
            const ecDivider = EcUnits.find((element) => element.value === unitForEC)
            const unitConversionForEC = resp.value/ecDivider.value;
            setValue(unitConversionForEC);
            updateChartData(parseFloat(unitConversionForEC));
            setPrevValue(resp.value);
          } else {
            setValue(resp.value);
            setPrevValue(resp.value);
            updateChartData(parseFloat(resp.value));
          }
          // setValue(resp.value);
          // setPrevValue(resp.value);
          // updateChartData(parseFloat(resp.value));
        }
    });

    return () => unsubscribe();
  }
}, [uid, usuario.username, currentMac,numberOfCans,typeOfCans, prevValue, updateChartData]);

useEffect(() => {
  
    const docPath = `${usuario.username}/infoDevices/${uid.split("@")[0]}/${uid.split("@")[1]}/configModule/${uid}`;
    const renderDoc = db.doc(docPath);

    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      if(docSnapshot.exists){
        const data = docSnapshot.data();
        setHeadName(data.item.name)
      }
        
    });

    return () => unsubscribe();
  
}, [uid, usuario.username]);

  return (
    <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    style={style}
    spacing={1} // Espacio general entre los componentes
    >
      <Grid item>
        <HeadTile name={headName} uid={uid} col={col} />
      </Grid>
      {/* Valor del Sensor */}
      <Grid item style={{ marginBottom: '5px' }}>
        {isDataFlag ? (
          <h5 style={{ textAlign: "center", margin: 0 }}>
            <span className="badge badge-dark">Sensor desconectado</span>
          </h5>
        ) : (
          <h5 style={{ textAlign: "center", margin: 0 }}>
            <span className="badge badge-dark">{value}</span>
          </h5>
        )}
      </Grid>

      {valArrayChart.length > 1 ? (
        <Grid item >
          {/* <Paper className={classes.paperGraphics} elevation={0}> */}
          <Paper className={classes.paperGraphics} elevation={0} style={{ padding: '10px 0px', marginTop: '0px' }}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                {/* <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    time: {
                      timezoneOffset: getTimezoneOffsetInSeconds(),
                    },
                    chart: {
                      height: "150px",
                      type: "area",
                      backgroundColor: "transparent",
                      borderRadius: 10,
                    },
                    series: [
                      {
                        data: valArrayChart,
                        label: name,
                        name: name,
                        type: "area",
                        color: "#5BB033",
                        fillColor: {
                          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                          stops: [
                            [0, Highcharts.color("#5BB033").setOpacity(0.5).get("rgba")],
                            [1, Highcharts.color("#5BB033").setOpacity(0).get("rgba")],
                          ],
                        },
                      },
                    ],
                    title: {
                      text: "",
                      margin: 0,
                    },
                    xAxis: {
                      type: "datetime",
                      lineColor: "transparent",
                      labels: {
                        enabled: false,
                      },
                    },
                    yAxis: [
                      {
                        gridLineColor: "transparent",
                        title: {
                          text: "",
                        },
                        labels: {
                          enabled: false,
                        },
                      },
                    ],
                    legend: {
                      enabled: false,
                    },
                    credits: {
                      enabled: false,
                    },
                  }}
                /> */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    time: {
                      timezoneOffset: getTimezoneOffsetInSeconds(),
                    },
                    chart: {
                      height: 40, // Reducir la altura para evitar el desbordamiento
                      type: "area",
                      backgroundColor: "transparent",
                      borderRadius: 10,
                      spacing: [1, 1, 1, 1], // Reducir el espaciado interno (arriba, derecha, abajo, izquierda)
                      margin: [1, 1, 1, 1], // Reducir los márgenes para evitar espacios
                    },
                    series: [
                      {
                        data: valArrayChart,
                        label: name,
                        name: name,
                        type: "area",
                        color: "#5BB033",
                        marker: {
                          enabled: false, // Oculta los puntos por defecto
                          states: {
                            hover: {
                              enabled: true, // Muestra el punto solo al hacer hover
                            },
                          },
                        },
                        fillColor: {
                          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                          stops: [
                            [0, Highcharts.color("#5BB033").setOpacity(0.5).get("rgba")],
                            [1, Highcharts.color("#5BB033").setOpacity(0).get("rgba")],
                          ],
                        },
                      },
                    ],
                    title: {
                      text: "",
                      margin: 0,
                    },
                    subtitle: {
                      text: "", // Asegurarse de no tener subtítulos para evitar espacio adicional
                    },
                    xAxis: {
                      type: "datetime",
                      lineColor: "transparent",
                      labels: {
                        enabled: false,
                      },
                    },
                    yAxis: [
                      {
                        gridLineColor: "transparent",
                        title: {
                          text: "",
                        },
                        labels: {
                          enabled: false,
                        },
                      },
                    ],
                    legend: {
                      enabled: false,
                    },
                    credits: {
                      enabled: false,
                    },
                    responsive: {
                      rules: [
                        {
                          condition: {
                            maxWidth: 500, // Hacer la gráfica más pequeña si el ancho es menor que 500px
                          },
                          chartOptions: {
                            chart: {
                              height: 80,
                            },
                          },
                        },
                      ],
                    },
                  }}
                />

              </Grid>
  
              {/* Valores Máximo y Mínimo */}
              <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="inherit" className={classes.minmaxText}>
                    {maxVal}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="inherit" className={classes.minmaxText}>
                    {minVal}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.paperGraphics} elevation={0}>
            <Typography variant={"h6"} className={classes.noGraphText}>
              Sin Gráfica
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(TestContinousSensorTile, (prevProps, newProps) => {
  return prevProps.data.uid === newProps.data.uid;
});
