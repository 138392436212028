import React, { useContext, useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, IconButton,  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, useMediaQuery, withStyles } from '@material-ui/core'
import { AddCircle, CloudUploadOutlined} from '@material-ui/icons';
import { IrrigationContext } from '../../../context/IrrigationContext/IrrigationContext';
import { AutomaticRows } from './components/AutomaticRows';
import { db } from '../../../config/firebase';
import { green } from '@material-ui/core/colors';
import { UserContext } from '../../../context/UserProvider';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3B3C43",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
     marginLeft: 12,
  },
  table: {
    minWidth: 650,
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export const AutomaticIrrigation = ({ canId, macId, handleAlert, }) => {
    const classes = useStyles();
    const {rows, setRows, startHour, minutos,segundos,setPumpsSelected, setWaterSelected,
      setZonesSelected,setDaysOfWeek,setTimeON,setTimeOFF,setEndHour,setMinutos,setSegundos,setStartHour,
      disableAutoButton, endHour, timeON, timeOFF,setDisableAutoButton,pumpsSelected,zonesSelected,
      daysOfWeek,waterSelected, autoFlagSave, setAutoFlagSave, typeSwitch,setTypeSwitch, removeFlag,
      rowsBeforeRemoved,days
    } = useContext(IrrigationContext);
    const {usuario,names} = useContext(UserContext)
    const actualPumps = [...pumpsSelected]
    const actualWaters = [...waterSelected]
    const actualZones = [...zonesSelected]
    const actualDays = [...daysOfWeek]
    const actualStartH = [...startHour]
    const actualEndH = [...endHour]
    const actualMinuts = [...minutos]
    const actualSeconds = [...segundos]
    const actualTimeH = [...timeON]
    const actualTimeL = [...timeOFF]
    const actualRows = []
    const actualConfig = [...typeSwitch]  
    const matches = useMediaQuery(theme => theme.breakpoints.down('sm')); 
    const [dataObtainedFlag, setDataObtainedFlag] = useState(false) 

    const addRule = () => {
      //setRuleId(prevValue => prevValue + 1 )
      const lengthRows = rows.length;
      console.log("Esto es lengthRows:",lengthRows)
      const allRows = lengthRows !== 0 ? [...rows] : [];
      if(lengthRows < 10){
        const newRuleId = lengthRows + 1;
        const newRow = lengthRows !== 0 ? [...allRows ,newRuleId] : [newRuleId]
        setRows(newRow)
      }
      
    }

    const sendingIrrigationRoutine = async (e, n) => {

      const item = {
          msMqtt: e,
          mac: macId,
          action: "Automatic Routine",
          fecha: Date.now(),
          uid: n
      }
  
      try {
          const addr = `${usuario.username}/infoDevices/${macId}/${canId}/sendConfigModule`
          await db.collection(addr).doc("sendConfig").set({ item })
          //console.log("Esto es macId:", macId)
      } catch (error) {
          console.log(error)
      }
    }

    const assignTypeConfig = (type) => {
    const config = type.checkedA ? 1 : (type.checkedB ? 2 : 0);
    return config
    }

    const handleSendMessage = (indexRule) => {
      let mqtt = "";
      const len = 34;
      const action = 245;
      //const dataOuts = autoArray[indexRule]
      const dataOuts = transformOutsToNumber(indexRule)
      //const daysToSend = automaticDays[indexRule];
      const daysToSend = transformDaysToNumber(indexRule);
      const hourEnd = endHour[indexRule].split(":")
      const hourStart = startHour[indexRule].split(":")
      const typeConfig = assignTypeConfig(typeSwitch[indexRule])

        // const buttonActive = toggleValor();
        mqtt =
            len +
            "," +
            action +
            "," +
            canId + 
            "," + 
            typeConfig+ 
            "," + 
            indexRule+ 
            "," + 
            [...dataOuts] +
            "," +
            hourStart[0] +
            "," +
            hourStart[1] +
            "," +
            hourEnd[0] + 
            "," +
            hourEnd[1] +
            "," +
            minutos[indexRule] +
            "," +
            segundos[indexRule] +
            "," +
            timeON[indexRule] +
            "," +
            timeOFF[indexRule] +
            "," +
            [...daysToSend];
      console.log("Esto es mqtt:",mqtt);
      const nodeUid = macId + "@" + canId;
      //console.log(nodeUid);
      sendingIrrigationRoutine(mqtt,nodeUid);
      return new Promise(resolve => setTimeout(resolve, 2000));
    }

    const transformOutsToNumber = (index) => {
      const helpArray = Array(16).fill(0);
        
      if(pumpsSelected[index]) {
        const elements = pumpsSelected[index]
        for (let i = 0; i < pumpsSelected[index].length; i++) {
          const indexOfElement = names.indexOf(elements[i])
          helpArray[indexOfElement] = 1;
        }
      }

      if(waterSelected[index]) {
        const elements = waterSelected[index]
        for (let i = 0; i < waterSelected[index].length; i++) {
          const indexOfElement = names.indexOf(elements[i])
          helpArray[indexOfElement] = 1;
        }
      }

      if(zonesSelected[index]) {
        const elements = zonesSelected[index]
        for (let i = 0; i < zonesSelected[index].length; i++) {
          const indexOfElement = names.indexOf(elements[i])
          helpArray[indexOfElement] = 1;
        }
      }
      return helpArray
    }

    const transformDaysToNumber = (index) => {
      const newDaysArray = Array(7).fill(0);
      if(daysOfWeek[index]) {
        const elements = daysOfWeek[index]
        for (let i = 0; i < daysOfWeek[index].length; i++) {
          const indexOfElement = days.indexOf(elements[i])
          newDaysArray[indexOfElement] = 1;
        }
      }
      return newDaysArray 
    }


    const handleSendButton = async () => {
      setDisableAutoButton(true);
      handleAlert(true,"info");
      const indexesToSend = removeFlag ? [...rowsBeforeRemoved] : [...rows];
      for (let index = 0; index < indexesToSend.length; index++) {
        await handleSendMessage(index)
      }
      
    }

    const searchForTheOutletName = async (uids) => {
      const address = `${usuario.username}/infoDevices/${macId}/${canId}/configModule`;
      try {
        const outletsNames = [];
        for (let index = 0; index < uids.length; index++) {
          const docRef = db.collection(address).doc(uids[index])
          const docSnap = await docRef.get();
          const data = docSnap.data();
          const outletName = data.item.name
          outletsNames.push(outletName)
        }
        return outletsNames;
      } catch (error) {
        console.error("Error al intentar obtener el nombre del outlet:",error)
      }
    }

    const getAndSaveUids = (arrayOfNames) => {
      try {
        const outletUids = [];
        for (let index = 0; index < arrayOfNames.length; index++) {
          const outId = names.indexOf(arrayOfNames[index])
          const uidCreated = `${macId}@${canId}@5@${outId}`
          //console.log("Esto es uidCreated:",uidCreated)
          outletUids.push(uidCreated)
        }
        //console.log("Esto es outletsUids:",outletUids)
        return [...outletUids]

      } catch (error) {
        console.error("Error en el guardado de los uids en riego automatico:",error)        
      }
    }

    useEffect(() => {
      const getDataDB = async () => {
        try{
          if( macId !== ""){
            const addr = `${usuario.username}/infoDevices/${macId}/${canId}/irrigationConfig`;
            const docRef = db.collection(addr).doc("automatic");
            const docSnap = await docRef.get();
            if (docSnap.exists) {
              const data = docSnap.data();
              const rowsObtained = data.rowsSaved
              
              //console.log("Esto es data.rowsSaved:",data.rowsSaved)
              for (let index = 0; index < rowsObtained.length; index++) {
                
                actualPumps[index] = await searchForTheOutletName(rowsObtained[index].pumps);
                actualWaters[index] = await searchForTheOutletName(rowsObtained[index].water);
                actualZones[index] = await searchForTheOutletName(rowsObtained[index].zones);
                actualStartH[index] = rowsObtained[index].startHour;
                actualEndH[index] = rowsObtained[index].type2.endHour;
                actualMinuts[index] = rowsObtained[index].type1.minuts;
                actualSeconds[index] = rowsObtained[index].type1.seconds;
                actualTimeH[index] = rowsObtained[index].type2.minutsON;
                actualTimeL[index] = rowsObtained[index].type2.minutsOFF;
                actualDays[index] = rowsObtained[index].days;  
                actualRows.push(rowsObtained[index].row)  
                actualConfig[index] = rowsObtained[index].actualConfig === 1 ?  {...actualConfig[index],checkedA:true} :
                 (rowsObtained[index].actualConfig === 2 ? {...actualConfig[index],checkedB:true} : actualConfig[index]); 
                                
              }
              
              setPumpsSelected([...actualPumps])
              setWaterSelected([...actualWaters])
              setZonesSelected([...actualZones])
              setStartHour([...actualStartH])
              setEndHour([...actualEndH])
              setMinutos([...actualMinuts])
              setSegundos([...actualSeconds])
              setTimeON([...actualTimeH])
              setTimeOFF([...actualTimeL])
              setDaysOfWeek([...actualDays])
              setRows([...actualRows])
              setTypeSwitch([...actualConfig])
              setDataObtainedFlag(true)

            } else {
              setPumpsSelected([...actualPumps])
              setWaterSelected([...actualWaters])
              setZonesSelected([...actualZones])
              setStartHour([...actualStartH])
              setEndHour([...actualEndH])
              setMinutos([...actualMinuts])
              setSegundos([...actualSeconds])
              setTimeON([...actualTimeH])
              setTimeOFF([...actualTimeL])
              setDaysOfWeek([...actualDays])
              setRows([...actualRows])
              setTypeSwitch([...actualConfig])
              setDataObtainedFlag(true)
            }
          }
        } catch (error) {
          console.error("Error al obtener datos Automaticos:",error);
        }
      }
      getDataDB();
            
    }, [canId,macId,usuario.username])

    useEffect(() => {
      const saveData = async () => {
        const rowsSaved = rows.map((row,i) => {
          //const testArray = getAndSaveUids(zonesSelected[i])
          //console.log("Esto es testArray:",testArray)
          const dataToSave = {
            actualConfig:  typeSwitch[i].checkedA ? 1 : (typeSwitch[i].checkedB ? 2 : 0),
            days: [...daysOfWeek[i]],
            //pumps: [...pumpsSelected[i]],
            pumps: getAndSaveUids(pumpsSelected[i]),
            row: row,
            startHour: startHour[i],
            type1: {
              minuts: minutos[i],
              seconds: segundos[i]
            },
            type2: {
              endHour: endHour[i],
              minutsON: timeON[i],
              minutsOFF: timeOFF[i]
            },
            //water: [...waterSelected[i]],
            water: getAndSaveUids(waterSelected[i]),
            //zones: [...zonesSelected[i]]
            zones: getAndSaveUids(zonesSelected[i])
          }
          return {...dataToSave}
        })
  
        try {
            const addr = `${usuario.username}/infoDevices/${macId}/${canId}/irrigationConfig`
            await db.collection(addr).doc("automatic").set({ rowsSaved })
            setAutoFlagSave(false);
            
            //console.log("Esto es macId:", macId)
            // console.log(item)
        } catch (error) {
            console.log(error)
        }
      }

      if(autoFlagSave){
        saveData();
      }
      
    }, [autoFlagSave, daysOfWeek,pumpsSelected,waterSelected,canId,macId,minutos,segundos,startHour,zonesSelected,usuario.username,rows,
      endHour,timeOFF,timeON,typeSwitch
    ])
    
  return (
    <>
    {dataObtainedFlag ?
    <div style={{ marginTop: "15px" }}>
      {(matches && (rows.length !== 10)) && (
        <div style={{ marginBottom: "15px" }}>
          <Grid container justifyContent='center' alignItems='center'>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddCircle />}
              onClick={addRule}
            >
              Añadir regla
            </Button>
          </Grid>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.hideOnMobile}># Regla</StyledTableCell>
              <StyledTableCell className={classes.hideOnMobile}>Bombas</StyledTableCell>
              <StyledTableCell className={classes.hideOnMobile}>Fuentes de agua</StyledTableCell>
              <StyledTableCell className={classes.hideOnMobile}>Zonas</StyledTableCell>
              <StyledTableCell className={classes.hideOnMobile}>Hora de inicio</StyledTableCell>
              <StyledTableCell align='left' className={classes.hideOnMobile}>Activación única</StyledTableCell>
              <StyledTableCell align='center' className={classes.hideOnMobile}>Activación múltiple</StyledTableCell>
              <StyledTableCell align='center' className={classes.hideOnMobile}>Dias</StyledTableCell>
              <StyledTableCell className={classes.hideOnMobile}>
                  <IconButton key={"add_Rule"} color="primary" aria-label="add rule" component="span" onClick={addRule}>
                      <AddCircle />
                  </IconButton>
              </StyledTableCell>
              
            </TableRow>
          </TableHead>

          <TableBody>
          {rows?.map((rowId,index) => (
            <AutomaticRows Id={index} numberOfRule={rowId} />
          ))} 

          </TableBody>

        </Table>
      </TableContainer>
    </div>
    :
    <div style={{ textAlign: 'center', width: '100%', marginTop:"10px" }}>
      <h5>Cargando...</h5>
    </div>
    }

    <div style={{ marginTop: "35px", marginBottom: "10px" }}>
      <Grid container justifyContent='center' alignContent='center'>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          //disabled={rows.length === 0 || disableButton}
          disabled={disableAutoButton || startHour === "" || pumpsSelected.length === 0 || 
            zonesSelected.length === 0 || daysOfWeek.length === 0 || (minutos === 0 && segundos === 0)
          }
          startIcon={<CloudUploadOutlined />}
          onClick={handleSendButton}
        >
          Subir
          {disableAutoButton && <CircularProgress size={30} className={classes.buttonProgress} />}
        </Button>
        

      </Grid>
      
    </div>
    </>

  )
}
