import React from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import HeadTile from './HeadTile'

const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));


export const CalculatedParametersTile = ({ data, style, col }) => {
	const uid = data.uid;
	console.log("Esto es data:",data)
	const cardData = ["1","2"]
	const parameterTitles = ["DPV","DLI"]
	const classes = useStyles()

  return (
	<Grid
	container
	direction="row"
	justifyContent="center"
	alignItems="center"  // Cambiado a "center" para centrar verticalmente.
	style={style}
	>
		<HeadTile name={data.nameOfCard} uid={uid} col={col} lastUpdate={''} />
		<Grid
			item
			xs={6}
			container
			direction="column"
			alignItems="center"  // Alineación centrada de los elementos hijos.
			justifyContent="center"
			// className="parameter-grid"  // Clase CSS personalizada para aplicar estilos.
			>
			{/* <Typography variant="subtitle2" gutterBottom>
				{parameterTitles[0]}
			</Typography> */}
			<h5>
				<span className="badge badge-dark value-badge">{cardData[0]}</span>
			</h5>
		</Grid>
	</Grid>
  )
}
