import React, { useContext, useEffect, useState } from 'react'
import { backGroundList } from '../../../../constants/globalConst'
import { UserContext } from '../../../../context/UserProvider';
import { db, functions } from '../../../../config/firebase';
import moment from 'moment';
import { Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { CheckCircle, DoubleArrow, Save } from '@material-ui/icons';

const sendMqttMsgToLoraWan = functions.httpsCallable("sendMqttMsgToLoraWan");

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
}));

export const ConfigLoraWanController = (propiedades) => {
	const data = propiedades.data;
  const uid = data.ide;
  const outId = uid.split("@")[4]
  const deviceId = uid.split("@")[2];
  const classes = useStyles();
	const { usuario, userTimezone } = useContext(UserContext);
	const [newName, setNewName] = useState('');
  const [lastUpdate, setLastUpdate] = useState("")
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [nameSaved, setNameSaved] = useState(false);
  const [loadingSavingName, setLoadingSavingName] = useState(false)

  // Generamos las opciones para horas (0 a 5) y minutos/segundos (0 a 59)
  const hoursOptions = Array.from({ length: 6 }, (_, index) => index);
  const minutesSecondsOptions = Array.from({ length: 60 }, (_, index) => index);

	const handleNameChange = (e) => {
		setNewName(e.target.value);
	};

  // Funciones para manejar los cambios en cada selector
  const handleHoursChange = (event) => setHours(event.target.value);
  const handleMinutesChange = (event) => setMinutes(event.target.value);
  const handleSecondsChange = (event) => setSeconds(event.target.value);

  const handleSubmitTime = () => {
    // Convertir horas a segundos, sumar minutos y segundos y finalmente pasar a milisegundos
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    console.log('Tiempo total en milisegundos:', totalMilliseconds);
    sendMqttMsgToLoraWan({
      name: "Test",
			deviceId: deviceId,
			command: "rule",
			outId: Number(outId),
			stateToggle: 1,
      timeMs: totalMilliseconds
    })
  }

  const handleSaveName = async () => {
    // Aquí debes implementar la lógica para guardar el nuevo nombre en la base de datos
    console.log('Guardando nuevo nombre:', newName);
    try {
      const typeController = uid.split("@")[3];
      const outID = uid.split("@")[4]
      const addr =`${usuario.username}/loraDevices/nodes/${deviceId}/namesOfCards`;
      const docRef = db.collection(addr).doc("names");
      const docSnap = await docRef.get();
      if (docSnap.exists) {
        const dataItem = docSnap.data().allNames;
        const currentOutId = `${typeController}-${outID}`               
        const currentOutName = dataItem.map((element) => {
          if(element.id === currentOutId){
            element.name = newName;
            element.lastUpdate = new Date().toUTCString();
            return element;
          } else {
            return element;
          }
        });
        
        await docRef.update({
          allNames: currentOutName
        });

        setNameSaved(true)

      } else {
        console.log("No se encontró el documento!");

      }
       
    } catch (error) {
      
    }
  };



	const editar = async (e) => {
        e.preventDefault();
    
        // if (!newName.trim()) {
        //   setError("Ingrese Nombre");
        //   return;
        // }
        // setError(null);
       
        // setOpenWindow(true);
    };

	useEffect(() => {
    let isMounted = true; // Bandera para verificar si el componente está montado
  
    const obtenerDatos = async () => {
    try {
      const arrayDeCadenas = uid.split("@");
      const controllerId = arrayDeCadenas[2];
      const typeController = arrayDeCadenas[3];
      const outID = arrayDeCadenas[4]
      const addr = `${usuario.username}/loraDevices/nodes/${controllerId}/namesOfCards`;	  
      const leerDatosDB = async () => {
        try {
          const docRef = db.collection(addr).doc("names");
          const docSnap = await docRef.get();	  
          if (docSnap.exists) {
            const dataItem = docSnap.data().allNames;
            const currentOutId = `${typeController}-${outID}`
                          
            const currentName = dataItem.find(
              (element) => element.id === currentOutId
            );
    
            if (isMounted) {
              
              setNewName(currentName?.name); // Usa opcional chaining para evitar errores
            //   setNodeNumber(nodeNumberObtained);
              setLastUpdate(currentName?.lastUpdate);
            //   console.log("Esto es lastUpdate:",currentName?.lastUpdate)
            //   setNameDataSaved([...dataItem]);
            }
          } else {
          console.log("No se encontró el documento!");
          }
        } catch (error) {
          console.error("Error al obtener el documento:", error);
        }
      };
  
      await leerDatosDB();
    } catch (error) {
      console.error("Error en obtenerDatos:", error);
    }
    };
  
    obtenerDatos();
  
    return () => {
      isMounted = false; // Marca como desmontado en la limpieza
    };
  }, [uid, usuario.username]);

  useEffect(() => {
    if (nameSaved) {
      const timeoutId = setTimeout(() => {
        setLoadingSavingName(false);
        setNameSaved(false);
      }, 4000); // 4000 milliseconds = 4 seconds

      // Cleanup function to clear the timeout if the component unmounts or isLoading changes before the timeout finishes
      return () => clearTimeout(timeoutId);
    }
  }, [nameSaved]); 
  


  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <ul className="list-group">
            <li
              className="list-group-item"
              style={{ background: backGroundList }}
            >
              <div className="row">
                <div className=" col-10">
                  <h4 style={{ color: "white" }}>{newName}</h4>
                </div>                   
              </div>
            </li>
            <li className="list-group-item">

              <div className="row">
                <div className="col-6">Salida:</div>
                <div className="col-6">{outId}</div>
              </div>

              <div className="row">
                <div className="col-6">Nombre:</div>
                <div className="col-6">
                  <TextField
                    placeholder="Ingrese Nombre"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newName}
                    onChange={handleNameChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {nameSaved ? (
                            <CheckCircle color="primary" />
                          ) : (
                            <Tooltip title="Guardar Nombre" arrow>
                              <IconButton 
                              color="primary" 
                              onClick={() => {setLoadingSavingName(true);
                                 handleSaveName();}} 
                              edge="end" 
                              disabled={newName.trim() === '' || loadingSavingName} 
                              aria-label="save"
                              >
                                <Save />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              
              <div className="row">
                <div className="col-6">Última modificación:</div>
                <div className="col-6">
                  {moment(lastUpdate).tz(userTimezone).format('ddd, D [de] MMM [de] YYYY, HH:mm [Hrs.]')}
                </div>
              </div>
              <div style={{ marginTop: '15px' }}>
                <Divider />
              </div> 

              <Grid container direction="column" spacing={2} alignItems="center">
                <div style={{ marginBottom: '10px', textAlign:'center', marginTop:'10px', fontWeight: 'bold', fontSize: '24px'}}>
                  Activación por tiempo
                </div>
                <div className={classes.container}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="hours-label">Horas</InputLabel>
                    <Select
                      labelId="hours-label"
                      id="hours-select"
                      value={hours}
                      onChange={handleHoursChange}
                    >
                      {hoursOptions.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour.toString().padStart(2, '0')}h
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel id="minutes-label">Minutos</InputLabel>
                    <Select
                      labelId="minutes-label"
                      id="minutes-select"
                      value={minutes}
                      onChange={handleMinutesChange}
                    >
                      {minutesSecondsOptions.map((min) => (
                        <MenuItem key={min} value={min}>
                          {min.toString().padStart(2, '0')}min
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel id="seconds-label">Segundos</InputLabel>
                    <Select
                      labelId="seconds-label"
                      id="seconds-select"
                      value={seconds}
                      onChange={handleSecondsChange}
                    >
                      {minutesSecondsOptions.map((sec) => (
                        <MenuItem key={sec} value={sec}>
                          {sec.toString().padStart(2, '0')}s
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <Button 
                variant="contained" 
                color="primary" 
                fullWidth
                startIcon={<DoubleArrow />}
                onClick={handleSubmitTime}
                >
                  Enviar Activación Unica
                </Button>
              </Grid>


            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
