import React, { useEffect, useState, useContext } from "react";

import { Box, Grid, Typography } from "@material-ui/core";
import { sendCommandWhenToggle } from "../Functions";
import Toggle from "./Toggle";
import HeadTile from "./HeadTile";
import { ALL_KINDS } from "../../../../constants/globalConst";
import { UserContext } from "../../../../context/UserProvider";
import { db } from "../../../../config/firebase";
import { get_Uid_State_CurrenteValue_ForToggle } from "../../../../context/functions/DashboardFunctions/divideUIDByKind";
import { getAllDeviceDataFromToModule } from "../../../../context/functions/DashboardFunctions/sharedFunctions";

const DiscreteOutTile = ({
  col,
  data,
  onClickCrossButton,
  style,
  kind,
}) => {
  const { name, uid } = data;
  const { usuario, dataMacCan } = useContext(UserContext);
  const { username } = usuario;
  const { OUT_AC } = ALL_KINDS;
  const [currentValue, setCurrentValue] = useState(0);
  const [state, setState] = useState("0");
  const [posicion, setPosicion] = useState("2");
  const [colorLabel, setColorLabel] = useState("text.disabled")
  const [designatedColor, setDesignatedColor] = useState("text.disabled")
  const [autoChangeFlag, setAutoChangeFlag] = useState(false);

  useEffect(() => {
    getAllDeviceDataFromToModule(uid.split("@")[0], uid.split("@")[1], username)
      .then((resp) => {
        const getTogglePosition = (toModuleData) => {
          // console.log('Esto es uid:', uid);
          const toggleFound = toModuleData.find((item) => item.id === uid);
          //console.log('Esto es toggleFound:', toggleFound);
          let positionIfNotFoundSetDefault = toggleFound
            ? toggleFound.posicion
            : "2";
          return positionIfNotFoundSetDefault;
        };
        let data = getTogglePosition(resp);
        //console.log("Esto es uid:",uid)
        // console.log('Esto es data:', data);
        setPosicion(data);
      })
      .catch((err) => {
        console.log({ err });
        setPosicion("2");
      });
  }, [uid, username]);

  useEffect(() => {
    const canId = String(uid.split("@")[1]);
    const mac = String(uid.split("@")[0]);
    const kind = String(uid.split("@")[2]);
    const outid = String(uid.split("@")[3]);
    const idxMac = dataMacCan.findIndex((elmt) => {
      return String(elmt.mac) === String(mac);
    });
    
    const idxCan = dataMacCan[idxMac]?.cans.findIndex((elmt) => {
      return String(elmt.id) === String(canId);
    });
    
    
    
    const { username } = usuario;
    const docPath = `${username}/infoDevices/${mac}/${canId}/fromModule/render`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      //console.log('Esto es dataMacCan[idxMac]:', dataMacCan[idxMac]);
      if(dataMacCan[idxCan] !== undefined) {
        const resp = get_Uid_State_CurrenteValue_ForToggle({
          fromModuleActual: data,
          kind: kind,
          outid: outid,
          toModuleDataActual:[],
          typeOfModule: dataMacCan[idxMac].cans[idxCan].type,
        });

        // if (resp.posicion !== posicion) {
        //   if(resp.posicion === "0") {
        //     setColorLabel("text.disabled")
        //   }
        //   setPosicion(resp.posicion);
        // }
      }      
      
    });

    return () => {
      unsubscribe();
    };
  }, [usuario, uid, dataMacCan]);

  useEffect(() => {
    const canId = String(uid.split("@")[1]);
    const mac = String(uid.split("@")[0]);
    const { username } = usuario;
    const docPath = `${username}/infoDevices/${mac}/${canId}/toModule/${uid}`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      if(data){
        setPosicion(data.posicion)
        if(data.posicion === "2"){
          setAutoChangeFlag(true);
        }
      }
    })
  
    return () => {
      unsubscribe();
    };
  }, [uid,usuario])
  

  useEffect(() => {
    if(posicion === "1"){
      return setDesignatedColor("success.main")
    } else if(posicion === "0" || posicion === "2") {
      return setDesignatedColor("text.disabled")
    }
  }, [posicion])

  useEffect(() => {
    if(autoChangeFlag) {
      if(state === "1" && posicion === "2"){
        return setColorLabel("success.main")
      } else if(state === "0" && posicion === "2") {
        return setColorLabel("text.disabled")
      }
    }
  }, [posicion,state,autoChangeFlag])

  useEffect(() => {
    const canId = String(uid.split("@")[1]);
    const mac = String(uid.split("@")[0]);
    const outId = String(uid.split("@")[3]);
    const docPath = `${usuario.username}/infoDevices/${mac}/${canId}/fromModule/togglesInfo`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      if(data) {
      const statesArray = [...data.states]
      const currentsArray = [...data.currents]
      setState(statesArray[Number(outId)])
      setCurrentValue(currentsArray[Number(outId)])  
      }    
    });

    return () => {
      unsubscribe();
    };

  }, [usuario,uid])
  
  useEffect(() => {
    const canId = String(uid.split("@")[1]);
    const mac = String(uid.split("@")[0]);
    const outId = String(uid.split("@")[3]);
    const docPath = `${usuario.username}/infoDevices/${mac}/${canId}/fromModule/configOK`;
    const renderDoc = db.doc(docPath);
    const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
      const data = docSnapshot.data();
      if(data) {
        if(data.accion === 4 && data.act === 'recOK' && (String(data.outid) === outId)) {
          if((String(data.val)) === posicion) {
            setColorLabel(designatedColor)
            if(posicion === "2"){
              setAutoChangeFlag(true)
            } else {
              setAutoChangeFlag(false)
            }
          }
          
        }  
      }    
    });

    return () => {
      unsubscribe();
    };

  }, [usuario,uid,designatedColor,posicion])

  async function handleToggle(e) {
    updateToggleOnallDataToggle(e);
    const toggle = {
      canid: uid.split("@")[1],
      outid: uid.split("@")[3],
      posicion: e.value,
      mac: uid.split("@")[0],
      uid: uid,
    };
    console.log({ posicionSet: e.value, toggle });

    await sendCommandWhenToggle(username, toggle);
  }

  function updateToggleOnallDataToggle(e) {
    setPosicion(e.value);
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
      <HeadTile
        name={name}
        uid={uid}
        onClickCrossButton={onClickCrossButton}
        col={col}
      />

      <Grid item xs={8} />

      <Grid item xs={2}>
        {kind === OUT_AC ? currentValue : ""}
      </Grid>

      <Grid item xs={2}>
        {/* {console.log("Esto es state=== ON:",state===ON)}
        {console.log('Esto es state:', state)} */}
        <Typography component="div" variant="body1">
          <Box
            //color={(state === ON && posicion !== "0") ? "success.main" : "text.disabled"}
            color={colorLabel}
            fontWeight="fontWeightBold"
          >
            ON        
            {/* {console.log('Esto es state:', state)}
            {console.log('Esto es stateString:', stateString)} */}
          </Box>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Toggle
          handleToggle={handleToggle}
          id={uid}
          posicion={posicion}
          disabled={col === 0 ? true : false}
        />
        
      </Grid>
    </Grid>
  );
};

export default React.memo(DiscreteOutTile, (prevProps, newProps) => {
  return prevProps.data.uid === newProps.data.uid;
});
