import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Grid, Snackbar, Typography } from '@material-ui/core'
import HeadTile from './HeadTile'
import Toggle from './Toggle';
import { db, functions } from '../../../../config/firebase';
import { UserContext } from '../../../../context/UserProvider';
import { Alert } from '@material-ui/lab';
import { DashboardContext } from '../../../../context/DashboardProvider';
const sendMqttMsgToLoraWan = functions.httpsCallable("sendMqttMsgToLoraWan");

export const LoraWanControllerTile = ({ data, style, col }) => {
	const { usuario  } = useContext(UserContext);
	const { setOpenLwToggleAlert, lwToggleAlertData,setLwToggleAlertData } = useContext(DashboardContext)
	const uid = data.uid;
	const deviceId = uid.split("@")[2]
	const outId = uid.split("@")[4]
	const [controllerName, setControllerName] = useState("-")
	const [togglePosition, setTogglePosition] = useState("0")
	const [statusLabelColor, setStatusLabelColor] = useState("text.disabled")
	const [openAlert, setOpenAlert] = useState(false);

	const handleToggleChange = (event) => {
		setTogglePosition(event.value)
		if (event.value === "1") {
			setStatusLabelColor("success.main")
		} else {
			setStatusLabelColor("text.disabled")
		}
		sendMqttMsgToLoraWan({
			name: "Test",
			deviceId: deviceId,
			command: "output",
			outId: Number(outId),
			stateToggle: Number(event.value)
		})
		setOpenAlert(true)
		setOpenLwToggleAlert(true)
	}

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
	  
		setOpenAlert(false)
	}

	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true; // Indicar que el componente está montado
		const getConfigController= async () => {
			try {
				const addr = `${usuario.username}/loraDevices/nodes/${deviceId}/namesOfCards`;
				const docRef = db.collection(addr).doc("names");
				const docSnap = await docRef.get();
				if (docSnap.exists) {
					const allSensorNames = docSnap.data().allNames;
					const controllerId = uid.split("@")[3];
					const outId = uid.split("@")[4]
					const nameId = `${controllerId}-${outId}`
					const idFounded = allSensorNames.find(item => item.id === nameId);
					const nameFounded = idFounded ? idFounded.name : "Toggle-LW";
					setControllerName(nameFounded)

				} else {
					setControllerName("Toggle-LW")
				}
			} catch (error) {
				console.error("Error en obtener datos del toggle:",error)
				setControllerName("Toggle-LW")
			}
			
			

		};
		getConfigController();
		return () => {
			isMountedRef.current = false; // Indicar que el componente se ha desmontado
		};
	  
	}, [deviceId,uid,usuario.username])
	

  return (
	<Grid
	container
	direction="row"
	justifyContent="center"
	alignItems="flex-start"
	style={style}
	>
		<HeadTile name={controllerName} uid={uid} col={col} />

		<Grid item xs={10} />

		<Grid item xs={2}>
			{/* {console.log("Esto es state=== ON:",state===ON)}
			{console.log('Esto es state:', state)} */}
			<Typography component="div" variant="body1">
				<Box
				//color={(state === ON && posicion !== "0") ? "success.main" : "text.disabled"}
				color={statusLabelColor}
				fontWeight="fontWeightBold"
				>
				ON        
				{/* {console.log('Esto es state:', state)}
				{console.log('Esto es stateString:', stateString)} */}
				</Box>
			</Typography>
		</Grid>

		<Grid item xs={12}>
			<Toggle
				handleToggle={handleToggleChange}
				id={uid}
				posicion={togglePosition}
				disabled={col === 0 ? true : false}
			/>
			
		</Grid>

		{/* <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
			<Alert onClose={handleCloseAlert} severity="success">
				This is a success message!
			</Alert>
		</Snackbar> */}

	</Grid>
  )
}
