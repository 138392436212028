import React from 'react'
import './Toggle.css'

function Toggle({handleToggle,id,posicion,disabled}) {

    const Js=[
        {id:"off"+id},
        {id:"on"+id},
        {id:"auto"+id}
    ];
    const isPumpDC = id.split("@")[2]
    const isLoraWanController = id.split("@")[1]
    return (
        <div className={`switch-toggle ${(isPumpDC === "6" || isLoraWanController === "lwcontroller") ? 'two-options' : 'three-options'}`}>
            <input 
              type="radio"
              name={id}
              value="0"
              id={Js[0].id}
              checked={posicion==="0"}
              onChange={(e)=>handleToggle(e.target)} 
              disabled={disabled?disabled:false}
              />
            <label htmlFor={Js[0].id} >{isPumpDC === "6" ? "MANUAL" : "OFF"}</label>

            {isLoraWanController !== "lwcontroller" && (
                <>
                    <input      
                    type="radio" 
                    name={id}
                    value="2"
                    id={Js[2].id}
                    checked={posicion==="2"}
                    onChange={(e)=>handleToggle(e.target)}
                    disabled={disabled?disabled:false}
                    />
                    <label htmlFor={Js[2].id}>
                        AUT
                    </label>
                </>
            )}
            
            {isPumpDC !== "6" && (
                <>
                    <input 
                    type="radio" 
                    name={id}
                    value="1"
                    id={Js[1].id}
                    checked={posicion==="1"}
                    onChange={(e)=>handleToggle(e.target)}
                    disabled={disabled?disabled:false}
                    />
                    <label htmlFor={Js[1].id} >ON</label>
                </>
            )}
           
        </div>
    )
}

export default React.memo(Toggle)
