import React, { useState } from 'react'
import { Button, CircularProgress, FormControl, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';


// Estilos personalizados para agregar colores según el rango
const useStyles = makeStyles((theme) => ({
	formControl: {
	  minWidth: 200,
	  marginBottom: theme.spacing(2),
	},
	menuItem: {
	  display: 'flex',
	  alignItems: 'center',
	  flexWrap: 'wrap',
	},
	colorBox: {
	  width: 20,
	  height: 20,
	  marginRight: theme.spacing(1),
	},
  }));
  
  // Definimos los rangos y colores según la imagen
  const ndviRanges = [
	{ label: '0.95 - 1.00 Vegetación densa', color: '#005a32', minValue: 0.95 ,maxValue: 1.0},
	{ label: '0.90 - 0.95 Vegetación densa', color: '#238443', minValue: 0.90 ,maxValue: 0.95 },
	{ label: '0.85 - 0.90 Vegetación densa', color: '#41ab5d', minValue: 0.85 ,maxValue: 0.90 },
	{ label: '0.80 - 0.85 Vegetación densa', color: '#78c679', minValue: 0.80 ,maxValue: 0.85 },
	{ label: '0.75 - 0.80 Vegetación densa', color: '#addd8e', minValue: 0.75 ,maxValue: 0.80 },
	{ label: '0.70 - 0.75 Vegetación densa', color: '#d9f0a3', minValue: 0.70 ,maxValue: 0.75 },
	{ label: '0.65 - 0.70 Vegetación densa', color: '#f7fcb9', minValue: 0.65 ,maxValue: 0.70 },
	{ label: '0.60 - 0.65 Vegetación densa', color: '#ffffe5', minValue: 0.60 ,maxValue: 0.65 },
	{ label: '0.55 - 0.60 Vegetación moderada', color: '#fee391', minValue: 0.55 ,maxValue: 0.60 },
	{ label: '0.50 - 0.55 Vegetación moderada', color: '#fec44f', minValue: 0.50 ,maxValue: 0.55 },
	{ label: '0.45 - 0.50 Vegetación moderada', color: '#fe9929', minValue: 0.45 ,maxValue: 0.50 },
	{ label: '0.40 - 0.45 Vegetación moderada', color: '#ec7014', minValue: 0.40 ,maxValue: 0.45 },
	{ label: '0.35 - 0.40 Vegetación escasa', color: '#cc4c02', minValue: 0.35 ,maxValue: 0.40 },
	{ label: '0.30 - 0.35 Vegetación escasa', color: '#993404', minValue: 0.30 ,maxValue: 0.35 },
	{ label: '0.25 - 0.30 Vegetación escasa', color: '#662506', minValue: 0.25 ,maxValue: 0.30 },
	{ label: '0.20 - 0.25 Vegetación escasa', color: '#a63603', minValue: 0.20 ,maxValue: 0.25 },
	{ label: '0.15 - 0.20 Suelo abierto', color: '#e6550d', minValue: 0.15 ,maxValue: 0.20 },
	{ label: '0.10 - 0.15 Suelo abierto', color: '#fd8d3c', minValue: 0.10 ,maxValue: 0.15 },
	{ label: '0.05 - 0.10 Suelo abierto', color: '#fdae6b', minValue: 0.05 ,maxValue: 0.10 },
	{ label: '-1.00 - 0.05 Suelo abierto', color: '#feedde', minValue: -1 ,maxValue: 0.5 },
  ];

export const FilteringIndexValues = ({ username, imageName, handleImageFiltering,handleNoFilter,loadingFilter, setLoadingFilter }) => {
	const classes = useStyles();
  	const [selectedRange, setSelectedRange] = useState('');

	// Maneja el cambio de selección
	const handleChange = (event) => {
		setSelectedRange(event.target.value);
		if (event.target.value === "") {
			console.log("No hay filtro")
			handleNoFilter()
		}
	};
	
	// Llamada a la Cloud Function
	const handleSubmit = async () => {
		setLoadingFilter(true)
		if (selectedRange) {
		  // Aquí debes llamar a tu Cloud Function, esto puede ser un fetch() o cualquier otra lógica.
		//   console.log(`Llamando a la Cloud Function con el rango: ${selectedRange}`);
		  const valuesSelected = ndviRanges.filter((item) => item.label === selectedRange)
		  const data = {
			username: username,
			imageId: imageName,
			minValue: valuesSelected[0].minValue,
			maxValue: valuesSelected[0].maxValue
		  }

		  const dataToSend = JSON.stringify(data);
		  console.log("Esto es dataToSend:",dataToSend)
		  handleImageFiltering(dataToSend)
		  
		}
	};

  return (
	<div>
      <FormControl className={classes.formControl}>
        <Typography>Selecciona un rango NDVI</Typography>
        <Select value={selectedRange} onChange={handleChange} displayEmpty>
          {/* <MenuItem value="" disabled>
            Selecciona un rango
          </MenuItem> */}
		  <MenuItem value="">
            <em>Todos los valores</em>
          </MenuItem>
          {ndviRanges.map((range, index) => (
            <MenuItem key={index} value={range.label} className={classes.menuItem}>
              <div
                className={classes.colorBox}
                style={{ backgroundColor: range.color }}
              ></div>
              {range.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
		{loadingFilter ? 
			<CircularProgress />
			
			:
			
			<Button
				variant="contained"
				color="primary"
				onClick={handleSubmit}
				disabled={!selectedRange || loadingFilter}
				size='small'
			>
				Filtrar
			</Button>
			
		}
    </div>
  )
}
