import React from 'react'
import { db } from '../../../../config/firebase'
import ConfigAlways from './ConfigOutACComponents/ConfigAlways';
import { AmbienteDataDefault } from '../../../../constants/ConfigOutDefault'
import { UserContext } from '../../../../context/UserProvider'
import 'moment/locale/es'
import { NutrientsForm } from './ConfigComponents/NutrientsForm';
import UpdatingButton from './ConfigComponents/UpdatingButton';
import DialogConfirm from './ConfigComponents/DialogConfirm';
import CalibrationDialogForm from './Calibration/CalibrationDialogForm';
import TransitionAlerts from '../../../Common/TransitionAlerts';
import { backGroundList } from '../../../../constants/globalConst';
import SelectMultiple from '../../../Common/SelectMultiple';
import InputSlider from './ConfigComponents/InputSlider';
import InputRange from './ConfigComponents/InputRange';
import DragChart from './ConfigComponents/DragChart';
import moment from 'moment';
import AdjustIcon from '@material-ui/icons/Adjust';
import { Button, IconButton, Tooltip, Grid, Box, MenuItem, Select, FormControl, makeStyles, Paper, Typography, Slider, withStyles, Tabs, Tab, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Save } from '@material-ui/icons';
const actionLink = "sendConfig"
const multiOptions = [
    { value: 0, name: 'pH Stabilizer' },
    { value: 2, name: 'Clever Nutrition' },
    // {value:2,name:'Nutrientes'},
    // {value:3,name:'Always'},
]
const multiFallback = [
    { value: 0, name: 'OFF' },
    { value: 1, name: 'ON' },
    { value: 2, name: 'AUTO' },
]

const selecOptions = [
    { value: 3, name: 'Por segundos' },
    { value: 4, name: 'Por mililitros' },
    // {value:5,name:'Calibración'},
]

const diaphragmPumpOptions = [
    { value: 3, name: 'Por segundos' },
    { value: 5, name: 'Por Litros' },
]

const textForValidationAlert = {
    peristaltic: ["Deslice el slider para seleccionar los segundos que va a activar la bomba.",
        "Deslice el slider para seleccionar los mililitros que desea obtener."
    ],
    diaphragm: ["Deslice el slider para seleccionar los segundos que va a activar la bomba.", "",
        "Deslice el slider para seleccionar los litros que desea obtener."
    ]
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PrettoSlider = withStyles({
    root: {
      color: 'primary',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
})(Slider);

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
		position: 'relative',
		width: "20%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
    },
    formControlManual: {
        margin: theme.spacing(1),
        width: "30%",
        textAlign:'center',
        [theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
      },
  }));

const infoStepper = [
    {
        tittleStep: "Preparación de la bomba",
        text: ` `,
        textStep: [
            {
                main: "Consideraciones antes de calibrar la bomba",
                points: [`Antes de continuar asegurese de purgar la bomba presionando el boton correspondiente de activación de bomba en el módulo
            de agua, deje de presionar cuando la manguera este llena y sin burbujas.`],
            },
            {
                main: "Probeta",
                points:[
                    "Recuerde tener una probeta de minimo 10 mlts para realizar las operaciones necesarias.",
                    "Recuerde tener una probeta de minimo 1 Lt para realizar las operaciones necesarias.",
                ]
            },
        ],
    },
    {
        tittleStep: "Calibración",
        text: `Asegurese de estar cerca del modulo de agua y siga los siguientes pasos:`,
        textStep: [
            {
                main: "",
                points: [
                    "a) Verifique que la manguera este llena y sin burbujas",
                    "b) De un lado de la bomba ponga el recipiente con el liquido que va a trabajar y del otro la probeta graduada.",
                    "c) Presione el boton de 'ENVIAR' el cual aparece debajo de este mensaje",
                    "d) El led de configuración en el módulo de agua cambiara a color cyan, y a continuación la bomba se pondra en modo calibracíon.",

                    "e) Cuando este todo listo, presione el boton de configuración en el módulo de agua,hasta que llegue a la medida de la probeta seleccionada, el led cambiara a color morado.",
                    "f) Deje de presionar el boton cuando el liquido llegue a la cantidad de mlts que usted haya seleccionado",]

            },

        ],
    },
    {
        tittleStep: 'Validación',
        text: ``,
        textStep: [
            { main: "Deslice el slider para seleccionar los segundos que va a activar la bomba." },
            { main: "Deslice el slider para seleccionar los mililitros que desea obtener." },
            { main: "Recuerda que es necesario validar esta nueva calibración." },

        ],
        textButton: "Enviar",
        labelSlider: ["Encender por 'x' seg.", "Encender hasta obtener 'x' mlts.", "Calibrar con probeta de 'x' mlts.","Calibrar con probeta de 'x' Lts."]

    },
    {
        tittleStep: 'Finalizar',
        text: "Proceso terminado",
        textButton: "Salir",
    },
];
const fromFloatToByteArray = (float) => {
    let data_float = new Float32Array(float.length);
    float.forEach((item, i) => {
        data_float[i] = item
    })
    return new Uint8Array(data_float.buffer)
}

const codifyRules = (comp) => {

    const data1ByteArray = fromFloatToByteArray(comp.data1)//Es float convertimos a array de bytes
    const dataMqtt = [comp.canid, comp.outid, comp.type, comp.fallback, ...data1ByteArray, comp.power];

    const actionMqtt = 0;
    const MSarray = [dataMqtt.length, actionMqtt, ...dataMqtt];
    let MS = MSarray[0] + '';
    for (let i = 1; i < MSarray.length; i++) {
        //array[i]=parseInt(MSarray[i]);
        MS = MS + ',' + MSarray[i];
    }
    return MS;
}

const defaultOpenAlert = {
    open: null,
    type: null,
    tittle: null,
    txt: null
}
const ConfigPumb = (propiedades) => {
    // const { usuario } = React.useContext(UserContext)
    const props = propiedades.data;
    const ide = props.ide;
    const outputid = parseInt(props.outid)
    const classes = useStyles();
    

    const [uid, setUid] = React.useState("");

    // const [comp,setComp]=React.useState(null);
    const [comp, setComp] = React.useState(null);

    const [error, setError] = React.useState(null);
    const [desactivar, setDesactivar] = React.useState(false);
    const [color, setColor] = React.useState("Green")

    const [nodeNameCache, setNodeNameCache] = React.useState()
    const {usuario, userTimezone}=React.useContext(UserContext)
    

    const [loading, setLoading] = React.useState(null)//Loading es para cargar , esto muestra el ciruclo cargando
    const [openWindow, setOpenWindow] = React.useState(false);

    const [openAlert, setOpenAlert] = React.useState(defaultOpenAlert)//Para abrir la Alerta de notificaciones
    const [dateSnap, setDateSnap] = React.useState("0")
    const [openCalibration, setOpenCalibration] = React.useState(false);
    //Nuevos
    const [rangeVali, setRangeVali] = React.useState(10);
    const [validationType, setValidationType] = React.useState(selecOptions[0].value);
    const [dataNT, setDataNT] = React.useState([...AmbienteDataDefault.pumbNT])
    const [dataPH, setDataPH] = React.useState([...AmbienteDataDefault.pumbPH])
    const [phSelectorVal, setPhSelectorVal] = React.useState(0);
    const [successFlag, setSuccessFlag] = React.useState(false);
    const [pumpType, setPumpType] = React.useState("");
    const [pumpTypeSaved, setPumpTypeSaved] = React.useState("")
    const [currentTogglePos, setCurrentTogglePos] = React.useState("0")
    const [configTabValue, setConfigTabValue] = React.useState(0)
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [commandSent, setCommandSent] = React.useState(false)

    React.useEffect(() => {
        const obtenerDatos = async () => {

            try {
                const leyendoDBOutConfi = async (mac, canid, nameUid) => {
                    try {
                        const addr = `${usuario.username}/infoDevices/${mac}/${canid}/configModule`
                        const data = await db.collection(addr).doc(ide).get();

                        const actualItem = { id: data.id, ...data.data() }
                        localStorage.setItem(nameUid, JSON.stringify(data.data().item))
                        setPumpType(actualItem.item.pumpType);
                        setPumpTypeSaved(actualItem.item.pumpType);
                        //setNode(actualItem)
                        if(actualItem.item.outid === '2' || actualItem.item.outid === '3'){
                            setDataNT([...actualItem.commonData.data1]);
                            // console.log(actualItem.item.data1)
                        } else if(actualItem.item.outid === '0' || actualItem.item.outid === '1'){
                            setDataPH(actualItem.commonData.data1);
                            setPhSelectorVal(actualItem.commonData.phConfig)
                        }
                        

                        //setUid(actualItem.id)//uid de la base de datos
                        setComp(actualItem.item)//Toda la informacion del componente


                    } catch (error) {
                        console.log(error)
                    }
                }

                const arrayDeCadenas = ide.split("@");
                const mac = arrayDeCadenas[0]
                const canid = arrayDeCadenas[1]
                const nameUid = `Config@${ide}`

                setUid(ide)
                setNodeNameCache(nameUid)

                // if (localStorage.getItem(nameUid)) {
                //     console.log("Leyendo desde cache")
                //     setComp(JSON.parse(localStorage.getItem(nameUid)))
                // } else {
                //     console.log("Leyendo desde base de datos")
                //     leyendoDBOutConfi(mac, canid, nameUid);
                // }

                console.log("Leyendo desde base de datos")
                leyendoDBOutConfi(mac, canid, nameUid);

            } catch (error) {
                console.log(error)
            }

        }
        obtenerDatos()


    }, [usuario.username, ide]);

    const editar = async (e) => {
        e.preventDefault()
        console.log("object")

        if (!comp.name.trim()) {
            setError("Ingrese Nombre")
            return
        }
        setError(null)
        setOpenWindow(true)
        
        //console.log(comp.data1)
        const data1ByteArray = fromFloatToByteArray(comp.data1)//Es float convertimos a array de bytes
        console.log(data1ByteArray);
        //fromFloatToByte(comp.data1)
    }

    const onFallback = React.useCallback((e) => {
        setComp({ ...comp, fallback: e.value })
    }, [comp])

    const onType = (e) => {
        const newArray = e.value === 0 ? [...AmbienteDataDefault.pumbPH] : [...AmbienteDataDefault.pumbNT];
        setComp({ ...comp, data1: newArray, type: e.value })
    };
    const onAlways = React.useCallback((e) => {
        console.log(e)
        const data1 = comp.data1;
        data1[0] = e.value;

        setComp(
            {
                ...comp,
                data1: data1,
            }
        )


    }, [comp]);

    const updateDrop = (e) => {
        //const dataY=e
        //console.log("e",data123)
        //console.log("Odata",e)

        setComp(
            {
                ...comp,
                data1: e.data1,
            }
        )
        console.log(e)

        setDesactivar(false)
        setColor("Green")
        //setColor2("Blue")
        console.log("dropfirst", e)
    }
    const editGraph = () => {
        setDesactivar(true)
        setColor("Orange")
        //setColor2("Red")
    }

    const handleChangeRange = (event, newValue) => {
        //console.log(newValue)
        let edit;
        if (comp.outid === "0" || comp.outid === "1") {
            edit = [...dataPH]
            edit[0] = newValue[0];
            edit[1] = newValue[1];
            setDataPH(edit);
        } else {
            edit = [...dataNT]
            edit[5] = newValue[0];
            edit[6] = newValue[1];
            setDataNT(edit);
        }
        //setValueRange(newValue);
        setComp({ ...comp, data1: edit })
    };

    const handleInputChangeRange = (event, i) => {
        let clone;
        if (comp.outid === "0" || comp.outid === "1") {
            clone = [...dataPH]
            clone[i] = event.target.value === '' ? '' : Number(event.target.value)
            const res = Math.abs(clone[1] - clone[0])
            if (res <= 0.5) {
                return
            }
            setDataPH(clone);
        } else {
            clone = [...dataNT]
            clone[i + 5] = event.target.value === '' ? '' : Number(event.target.value)
            const res = Math.abs(clone[6] - clone[5])
            if (res <= 0.5) {
                return
            }
            setDataNT(clone)
            
        }
        setComp({ ...comp, data1: clone })
    };

    const handleSliderChange = (event, newValue) => {
        setRangeVali(newValue)
    };

    const handleInputChange = (event) => {
        setRangeVali(event.target.value === '' ? '' : Number(event.target.value))
    };

    const handleBlur = () => {
        if (rangeVali < 0) {
            setRangeVali(0)
        } else if (rangeVali > 100) {
            setRangeVali(100)
        }
    };

    const handlePumpType = (event) => {
        const newPumpType = event.target.value
        const compCopy = comp;
        compCopy.pumpType = newPumpType;
        setPumpType(newPumpType)
        setComp(compCopy)        
        const initValidationType = newPumpType === "peristaltic" ? selecOptions[0].value : diaphragmPumpOptions[0].value;
        setValidationType(initValidationType)
        setRangeVali(10)
    }

    const handleTabChange = (event, newValue) => {
        setConfigTabValue(newValue);
    }

    const TypeConfi = () => {
        //console.log("dsfdsfdesfsd")
        //if(type==="0"){return (<ConfigFan data={props}/>)}

        const data123 = {
            data1: comp.data1,
            data2: [],
            data3: []
        }

        if (comp.type === "0" || comp.type === "1") { return (<></>) }
        else if (comp.type === "2") {
            return (
                <DragChart
                    data={{
                        data: { ...data123 },
                        updateDrop: updateDrop,
                        click: editGraph,
                        name: "Nutrientes",
                        head: "Nutrientes",
                        color: color,
                        ytext: "mlt",
                        xtext: "Semanas",
                        seriesName: "mlt de nutrientes por semana",
                        dragPrecisionY: 0.1,
                        dragMaxY: 50
                    }}
                />

            )
        }

        else if (comp.type === "3") {
            return (
                <ConfigAlways
                    data={data123}
                    handleToggle={onAlways}
                />
            )
        }


        else { return (<h3>Otro</h3>) }
    }

    const agreeDialog = async () => {//Aun no guarda solo manda a cloudfunctions para mandar a IOT
        //const item={...node, fecha:Date.now(),msMqtt:codifyRules(node.arrayRules,node.canid)}
        setLoading(true)

        const item = {
            msMqtt: codifyRules(comp),
            mac: comp.mac,
            action: actionLink,
            fecha: Date.now(),
            uid: nodeNameCache
        }

        try {
            const addr = `${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
            await db.collection(addr).doc("sendConfig").set({ item })
            setOpenWindow(false)//Solo abre la ventana

        } catch (error) {
            console.log(error)
        }
    }
    /* Notifica cuando se a terminado de configurar y cambia la bandera, loading permite modificar o cambiar*/
    React.useEffect(() => {
        const arrayDeCadenas = ide.split("@");
        const mac = arrayDeCadenas[0];
        const canid = arrayDeCadenas[1];
        const isTheUpdated = async () => {
            const addr1 = `${usuario.username}/infoDevices/${mac}/${canid}/sendConfigModule`
            const data = await (await db.collection(addr1).doc("sendConfig").get()).data()
            if (data.item.uid !== nodeNameCache) { return false }
            else { return true }
        }

        const add = usuario.username + '/infoDevices/' + mac + '/' + canid + '/fromModule';
        let unsubscribe = null; // Variable para almacenar la función de desuscripción
    
        const ChangeFireStore = async () => {
            unsubscribe = db.collection(add).where('act', '==', 'recOK')
                .onSnapshot(querySnapshot => {
                    querySnapshot.docChanges().forEach(async (change) => {
                        // ... tu código para manejar los cambios ...
                        if (change.type === 'modified') {
                            const breaking = await isTheUpdated()
                            if (breaking === false) { return }

                            setLoading(false)//Una ves que llega el mensaje desactiva el bloqueo, el loading
                            setOpenAlert({
                                open: true,
                                type: "success",
                                tittle: "Configuración Exitosa",
                                txt: "El modulo se configuro correctamente",
                            })
                            setPumpTypeSaved(pumpType)
                            const dataTime = change.doc.data().time
                            console.log("object")

                            /*
                            const item={...comp,   fecha:Date.now(),msMqtt:codifyRules(comp)}
                            const addr=`${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/configModule`
                            await db.collection(addr).doc(uid).update({item})
                            localStorage.setItem(nodeNameCache,JSON.stringify(item))
                            setComp(item)
                            */


                            setDateSnap(dataTime)

                        }
                    });
                });
        };
    
        if (comp && usuario.username) {
            ChangeFireStore();
        }
    
        // Función de limpieza
        return () => {
            if (unsubscribe) {
                unsubscribe(); // Desuscribirse al desmontar el componente
            }
        };
    }, [comp, usuario.username, nodeNameCache, dateSnap, ide,pumpType]);

    React.useEffect(() => {
        try{
          if(comp && comp.mac !== ""){
            const addr = usuario.username+'/infoDevices/'+comp.mac+'/'+comp.canid+'/fromModule/configOK';
            const unsubscribe = db.doc(addr).onSnapshot(snapshot => {
              const data = snapshot.data();
              if(data){
                if (data.accion === 213 && data.act === 'recOK' && data.kind === 0) {
                    const alertjs = {
                        open: true,
                        type: "success",
                        tittle: "Configuración Exitosa",
                        txt: "El modulo se configuro correctamente",
                    }
                    setOpenAlert(alertjs) 
                    setSuccessFlag(true);
                    setPumpTypeSaved(pumpType)
                            
                } 
              }
            })
            // Limpiar el listener al desmontar el componente
            return () => unsubscribe();
          }
          
        } catch(error) {
          console.error("Error al detectar la respuesta del embebido:",error)
        }
      }, [usuario.username])


    React.useEffect(() => {
        
        const arrayDeCadenas = ide.split("@");
        const mac = arrayDeCadenas[0];
        const actualUid = arrayDeCadenas[3];
        let nextUid = '0';
        let commonData = [];

        const savingDB = async () => {


            const item = { ...comp, fecha: Date.now(), msMqtt: codifyRules(comp) }
            const addr = `${usuario.username}/infoDevices/${mac}/${comp.canid}/configModule`

            // console.log('Esto es UID:');
            // console.log(uid);
            
            if (actualUid === '2' || actualUid === '3') {
                nextUid = actualUid === '2' ? '3' : '2';
                // const newComp = comp.filter(elemento => elemento !== 'name');
                commonData = { data1: [...comp.data1] , fecha: Date.now() }
            } else if(actualUid === '0' || actualUid === '1') {   
                nextUid = actualUid === '0' ? '1' : '0';
                commonData = { data1: [...comp.data1] , fecha: Date.now(),phConfig: phSelectorVal }              
            }

            const newUid = arrayDeCadenas[0] + "@" + arrayDeCadenas[1] + "@" + arrayDeCadenas[2] + "@" + nextUid;
            
            await db.collection(addr).doc(uid).update({ item });
            await db.collection(addr).doc(uid).update({ commonData });
            await db.collection(addr).doc(newUid).update({ commonData });
            localStorage.setItem(nodeNameCache, JSON.stringify(item))
            setComp(item);
            const timer = setTimeout(() => {
                const alertjs = {
                    open: false,
                    type: "info",
                    tittle: "Info",
                    txt: "Espere a que se configure correctamente GAPY-MASTER",
                }
                setOpenAlert(alertjs)
                setSuccessFlag(false)
              }, 3000); // Ocultar el alerta después de 2 segundos
        
              return () => {
                clearTimeout(timer);
              };


            //console.log("GuardandoDB", comp, nodeNameCache, uid, usuario.username)

        }
        console.log(dateSnap);
        if (dateSnap !== "0") { 
            console.log("Entre en SavingDB")
            savingDB() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateSnap])

    /* Notifica si Hubo un error o el mensaje cuando se envio al IoT*/
    React.useEffect(() => {
        const arrayDeCadenas = ide.split("@");
        const mac = arrayDeCadenas[0]
        // const canid = arrayDeCadenas[1]
        const add = usuario.username + '/logs/' + mac;
        let unsubscribe = null; // Variable para almacenar la función de desuscripción
    
        if (comp && usuario.username) {
            unsubscribe = db.collection(add)
                .where('act', '==', 'sendConfigModule')
                .onSnapshot(querySnapshot => {
                    querySnapshot.docChanges().forEach(change => {
                        if (change.type === 'modified') {
                            //console.log()
                            const data = change.doc.data()
                            if (data.action !== actionLink) { return }
                            if (data.uid !== nodeNameCache) { return }//si este uid no fue el que se actualizo no cambia parametros
                            let alertjs = {}
                            switch (data.state) {
                                case "Error":
                                    alertjs = {
                                        open: true,
                                        type: "error",
                                        tittle: "Error",
                                        txt: "Hubo un error al enviar: Verifique la conexión a internet de GAPY-Master",
                                    }
                                    break;
                                case "Info":
                                    alertjs = {
                                        open: true,
                                        type: "info",
                                        tittle: "Info",
                                        txt: "Espere a que se configure correctamente GAPY-MASTER",
                                    }
                                    break;
                                default:
                                    alertjs = defaultOpenAlert;
                                    break;
                            }
                            setOpenAlert(alertjs)
                        }
                    });
                });
        }
    
        // Función de limpieza
        return () => {
            if (unsubscribe) {
                unsubscribe(); // Desuscribirse al desmontar el componente
            }
        };
    }, [comp, usuario.username, setOpenAlert, nodeNameCache]);

    React.useEffect(() => {
        try {
            const arrayDeCadenas = ide.split("@");
            const mac = arrayDeCadenas[0]
            const canid = arrayDeCadenas[1]
            const obtainTogglePos = async() => {
                const addr = `${usuario.username}/infoDevices/${mac}/${canid}/toModule`
                const data = await db.collection(addr).doc(ide).get();
                if(data.exists){
                    const currentPos = data.data()
                    setCurrentTogglePos(currentPos.posicion)
                    const newConfigTabValue = currentPos.posicion === "0" ? 0 : 1;
                    setConfigTabValue(newConfigTabValue)
                }
            }
            obtainTogglePos()
        } catch (error) {
            console.error("Error en obtener la pocicion del toggle:",error)
        } 
    }, [usuario,ide])
    
    

    const close = (e) => {
        //console.log(e)
        if (openAlert.type === "error") {
            setLoading(false)
        }
        setOpenAlert(defaultOpenAlert)
    }


    const sendingOperationCalibration = async (e) => {

        const item = {
            msMqtt: e,
            mac: comp.mac,
            action: "Advanced Config",
            fecha: Date.now(),
            uid: nodeNameCache
        }


        try {
            const addr = `${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
            await db.collection(addr).doc("sendConfig").set({ item })
            // console.log("Esto es: e")
            // console.log(item)
        } catch (error) {
            console.log(error)
        }
    }
    //Aqui poner las funciones de validadcion de calibracion


    const handleValidationSelec = (e) => {
        setValidationType(e.target.value)
        const valueForSlider = e.target.value === 5 ? 1 : 10;
        setRangeVali(valueForSlider)
    }

    const handleSendVal = () => {
        let mqtt = "";
        let dosingCommand = "";
        const len = 4;
        const action = validationType === 3 ? 10 : (validationType === 5 ? 12 : 11);
        const [integerValue, decimalValue = 0] = rangeVali.toString().split('.');
        const doserType = validationType === 5 ? 4 : validationType;
        const pumpTypeToSend = pumpTypeSaved === "peristaltic" ? 0 : 1;
        const commandBasis = `${len},${action},${comp.canid},${comp.outid}`
        if(validationType === 3) {
            dosingCommand = `${pumpTypeToSend},${rangeVali}`;
        } else if(validationType === 4 || validationType === 5) {
            dosingCommand = validationType === 5 ? `${integerValue},${decimalValue}` : `${rangeVali},0`;
        }
        mqtt =
            commandBasis +
            "," +
            dosingCommand;
           
        console.log("Esto es mqtt:",mqtt)
        setOpenSnackBar(true)
        setCommandSent(true)
        sendingOperationCalibration(mqtt);
        // console.log("Esto es mqtt:",mqtt)
    }

    const handleCloseSnackBar = (event,reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false)
    }

    React.useEffect(() => {
        let timer;
        if (commandSent) {
          // Iniciar el temporizador al detectar que commandSent es true
          timer = setTimeout(() => {
            // Después de 5 segundos, volvemos commandSent a false
            setCommandSent(false);
          }, 5000);
        }
    
        // Limpiar el temporizador si el componente se desmonta o si cambia el valor de commandSent
        return () => {
          clearTimeout(timer);
        };
      }, [commandSent]);
    

    //Funcion para el formulario de nutrientes
    const handleNewData = (newArray) => {
        setDataNT(newArray);
        setComp({ ...comp, data1: newArray })
    }

    const sendingPhSelect = async (e) => {

        const item = {
            msMqtt: `2,213,${comp.canid},${phSelectorVal}`,
            mac: comp.mac,
            action: "Ph Selector",
            fecha: Date.now(),
            uid: nodeNameCache
        }
        const alertjs = {
            open: true,
            type: "info",
            tittle: "Info",
            txt: "Espere a que se configure correctamente GAPY-MASTER",
        }
        setOpenAlert(alertjs)

        try {
            const addr = `${usuario.username}/infoDevices/${comp.mac}/${comp.canid}/sendConfigModule`
            await db.collection(addr).doc("sendConfig").set({ item })
            // console.log("Esto es: e")
            // console.log(item)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePhSelectorChange = (event) => {
        setPhSelectorVal(event.target.value);
    }

    return comp && (
        <div>
            <form onSubmit={editar}>
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >

                    <Grid item lg={12} style={{ width: "100%" }}>

                        <ul className="list-group">
                            <li className="list-group-item" style={{ background: backGroundList }}>
                                <h3 style={{ color: "white" }}>Outs</h3>

                            </li>

                            <li className="list-group-item" >

                                <div className="row">
                                    <div className="col-5">
                                        Name:
                                    </div>
                                    <div className="col-7">
                                        <input
                                            type="text"
                                            placeholder="Ingrese Nombre"
                                            className="form-control mb-2"
                                            onChange={e => setComp({ ...comp, name: e.target.value })}
                                            value={comp.name}
                                            disabled={desactivar}
                                        ></input>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        Tipo de Bomba:
                                    </div>
                                    <div className="col-7">
                                        <FormControl 
                                        variant="outlined"
                                        fullWidth 
                                        size='small'
                                        // style={{ 
                                        //     width: '95%',
                                        //     justifyContent:'center',
                                        //     alignItems:'center',
                                        //     textAlign:'center'
                                        // }}
                                        >
                                            <Select
                                            labelId="select-pump-type"
                                            id="select-pumpType"
                                            value={pumpType}
                                            onChange={handlePumpType}
                                            >
                                                <MenuItem value={"peristaltic"}>Peristaltica (mlts)</MenuItem>
                                                <MenuItem value={"diaphragm"}>Diafragma (Lts)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        Out:
                                    </div>
                                    <div className="col-7">
                                        {comp.outid}
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-5">
                                        MAC:
                                    </div>
                                    <div className="col-7">
                                        {comp.mac}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-5">
                                        CAN:
                                    </div>
                                    <div className="col-7">
                                        {comp.canid}
                                    </div>
                                </div>

                                {
                                    comp.fecha && (
                                        <div className="row">
                                            <div className="col-5">
                                                Last Update:
                                            </div>
                                            <div className="col-7">
                                                {/* {moment(comp.fecha).format('llll')} */}
                                                {moment(comp.fecha).tz(userTimezone).format('ddd, D [de] MMM [de] YYYY, HH:mm [Hrs.]')}
                                            </div>
                                        </div>

                                    )
                                }
                            </li>
                        </ul>

                    </Grid>
                    </Grid>

                    <Paper variant="outlined">
                        {/* <Grid container> */}
                            {/* <Grid item lg={12} style={{ width: "100%" }}> */}
                                <li className="list-group-item" style={{ background: "#616161" }}>
                                    {/* <ul className="list-group"> */}
                                    <div className="row">
                                        <div className="col-10">
                                            <h4 style={{ color: "white" }}>Configuración- {comp.name}</h4>
                                        </div>



                                        <div className="col-2">
                                            <Tooltip title={<h6 style={{ color: "white" }}>Calibrar Bomba</h6>} arrow>

                                                <IconButton
                                                    color="inherit"
                                                    aria-label="menu"
                                                    style={{ color: "white" }}
                                                    onClick={() => { setOpenCalibration(true) }}
                                                >
                                                    <AdjustIcon fontSize='large'
                                                        style={{ color: "white" }}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                            <CalibrationDialogForm
                                                handleClose={() => { setOpenCalibration(false) }}
                                                open={openCalibration}
                                                dialogTittle="Calibración de bomba"
                                                dialogText="Para calibrar la bomba, favor de seguir los siguientes pasos:"
                                                infoStepper={infoStepper}
                                                sendingOperationCalibration={sendingOperationCalibration}
                                                canid={comp.canid}
                                                outid={comp.outid}
                                                typeCalibration="CalBomba"
                                                selecOptions={selecOptions}
                                                pumpType={pumpTypeSaved}
                                            />
                                        </div>
                                    </div>
                                    {/* </ul> */}
                                </li>   

                                <Paper square>
                                <Tabs
                                    value={configTabValue}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleTabChange}
                                    aria-label="disabled tabs example"
                                >
                                    <Tab label="Activación Manual" {...a11yProps(0)} />
                                    <Tab label="Activación Automatica" {...a11yProps(1)} />
                                </Tabs>
                                </Paper>                      
                                
                                {configTabValue === 0 &&( 
                                    <Grid
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center" 
                                    >
                                    <div style={{ marginBottom: '10px', textAlign:'center', marginTop:'10px', fontWeight: 'bold', fontSize: '24px'}}>
                                        Dosificación Manual
                                    </div>

                                    <Grid
                                    container
                                    direction='column'
                                    justifyContent="center"
                                    alignItems="center" 
                                    spacing={2}
                                    >
                                        <div style={{ marginTop: '15px' }}>Modo de dosificación:</div>

                                        <FormControl variant="outlined" className={classes.formControlManual}>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={validationType}
                                            onChange={handleValidationSelec}
                                            >
                                                {pumpTypeSaved === "peristaltic" && (
                                                    selecOptions.map((option,index) => (
                                                        <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                                                    ))
                                                )}
                                                {pumpTypeSaved === "diaphragm" && (
                                                    diaphragmPumpOptions.map((option,index) => (
                                                        <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                                                    ))
                                                )}
                                                
                                            </Select>
                                        </FormControl>

                                        {/* <SelectMultiple
                                            id={`TypeValidation`}
                                            value={validationType}
                                            handleToggle={handleValidationSelec}
                                            desactivar={false}
                                            arrayOptions={pumpType === 0 ? selecOptions : diaphragmPumpOptions}
                                            className="col-6"
                                            maxWidth='95%'
                                        /> */}
                                        
                                    </Grid>

                                    <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft:'25%' }}>
                                        <Typography id="input-slider" gutterBottom>
                                            {validationType === 3 && "Tiempo de activacion:"}
                                            {validationType === 4 && "Configura la cantidad de ml:"}
                                            {validationType === 5 && "Configura la cantidad de Lt:"}
                                        </Typography>
                                        <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
                                            
                                            <Grid item xs={6}>
                                                <PrettoSlider 
                                                valueLabelDisplay="auto" 
                                                aria-label="pretto slider" 
                                                value={rangeVali} 
                                                onChange={handleSliderChange}
                                                step={(pumpType === "diaphragm" && validationType === 5) ? 0.1 : 1}
                                                min={0}
                                                max={(pumpType === "diaphragm" && validationType === 5) ? 5 : 100}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography id="slider-labelInfo">
                                                    {validationType === 3 && `${rangeVali} seg.`}
                                                    {validationType === 4 && `${rangeVali} mlts.`}
                                                    {validationType === 5 && `${rangeVali} Lts.`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <Alert severity="info">
                                        <AlertTitle>
                                            <strong>{pumpType === "peristaltic" ? 
                                            textForValidationAlert.peristaltic[validationType - 3]
                                            : textForValidationAlert.diaphragm[validationType - 3]
                                        }</strong>
                                        </AlertTitle>
                                    </Alert>

                                    <div style={{ justifyContent:'center', alignItems:'center', textAlign:'center', marginTop:'10px', marginBottom:'10px' }}>
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSendVal}
                                        disabled={currentTogglePos !== "0" || commandSent }
                                        >
                                            Dosificar
                                        </Button>
                                    </div>
                                    <Snackbar open={openSnackBar} autoHideDuration={5000} onClose={handleCloseSnackBar}>
                                        <Alert onClose={handleCloseSnackBar} severity="success">
                                            Se ha enviado correctamente la instrucción de dosificación
                                        </Alert>
                                    </Snackbar>

                                    {currentTogglePos !== "0" && (
                                        <Alert severity="warning">
                                            <AlertTitle>
                                                <strong>
                                                    Cambia el Toggle a modo "MANUAL" para poder dosificar.
                                                </strong> 
                                            </AlertTitle>
                                        </Alert>
                                    )}

                                    </Grid>
                                )}
                                
                                {configTabValue === 1 && (
                                    <>
                                    <Box>
                                        <Grid container justifyContent="center"
                                        alignItems="center">
                                        <div style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}>
                                            {comp.outid === "0" || comp.outid === "1" ? multiOptions[0].name : multiOptions[1].name}

                                        </div>

                                        </Grid>

                                        
                                    </Box>
                                    {/* <TypeConfi/> */}
                                    {
                                        (comp.outid === "0" || comp.outid === "1") && (
                                            <>
                                            <div style={{ marginBottom: '30px'}}>
                                                <label className={"mt-3"}>Tendencia del cultivo en pH:</label>
                                                <FormControl className={classes.formControl}> 
                                                    <Select
                                                    labelId="select-ph-stabilize"
                                                    id="select-phStabilize"
                                                    value={phSelectorVal}
                                                    onChange={handlePhSelectorChange}
                                                    >
                                                        <MenuItem value={0}>Ascendente</MenuItem>
                                                        <MenuItem value={1}>Descendente</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    className={classes.button}
                                                    startIcon={<Save />}
                                                    onClick={sendingPhSelect}
                                                >
                                                    Guardar
                                                </Button>
                                            
                                            </div>
                                            <Box>
                                                <InputRange
                                                    value={[dataPH[0], dataPH[1]]}
                                                    handleInputChangeRange={handleInputChangeRange}
                                                    handleChangeRange={handleChangeRange}
                                                    label="Rango PH"
                                                />
                                            </Box>
                                            </>
                                            
                                        )
                                    }

                                    {(comp.outid === "2" || comp.outid === "3") && (
                                        <>
                                            <Box spacing={3}>
                                                <NutrientsForm
                                                    arrayData1={dataNT}
                                                    //arrayNames
                                                    handleNewData={handleNewData}
                                                />
                                                <div>
                                                    <InputRange
                                                        value={[dataNT[5], dataNT[6]]}
                                                        handleInputChangeRange={handleInputChangeRange}
                                                        handleChangeRange={handleChangeRange}
                                                        label="Rango EC"
                                                    />
                                                </div>
                                            </Box>
                                        </>
                                    )}
                                    {currentTogglePos !== "2" && (
                                        <Alert severity="warning">
                                            <AlertTitle>
                                                <strong>
                                                    Cambia el Toggle a modo "AUT" para que las configuraciones se apliquen.
                                                </strong>
                                            </AlertTitle>
                                        </Alert>
                                    )}
                                    </>
                                )}

                                {//como operador ternario
                                    desactivar && (
                                        <div className="col-12 mt-3">
                                            <div className="alert alert-warning alert-block">
                                                Mueva uno o mas puntos en la gráfica
                                            </div>

                                        </div>

                                    )
                                }

                            {/* </Grid> */}
                        {/* </Grid> */}
                    </Paper>

                {/* </Grid> */}
                <UpdatingButton
                    type="submit"
                    disabled={loading}
                />
            </form>
            <DialogConfirm
                open={openWindow}
                handleClose={() => setOpenWindow(false)}
                agreeDialog={agreeDialog}
                title={"¿Está seguro que quiere continuar con esta operación?"}
                text={
                    "Permite realizar configuraciones de la salidas o entradas del Nodo. Verifique que el Nodo que desea configurar este conectado através del GAPY-BUS"}

            />
            <TransitionAlerts
                data={openAlert}
                onClick={close}
            />
        </div>
    )
}

export default ConfigPumb
