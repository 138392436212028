import React, { useContext, useEffect, useState } from 'react'
import { Button, Grid, IconButton, makeStyles, Tab, Tabs, Tooltip } from '@material-ui/core'
import { AddCircle, Autorenew, DeleteForever, Edit, Refresh } from '@material-ui/icons';
import { NewCropConfigPopup } from './NewCropConfigPopup';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';
import { EditCropConfig } from './EditCropConfig';
import { db } from '../../../../config/firebase';
import { UserContext } from '../../../../context/UserProvider';
import moment from 'moment-timezone';
import { DeleteCropWindow } from './DeleteCropWindow';

const useStyles = makeStyles((theme) => ({
	root: {
	flexGrow: 1,
	width: '50%',
	//backgroundColor: theme.palette.background.paper,
	position: 'relative',
	},
	tabsContainer: {
	display: 'flex',
	alignItems: 'center',
	},
	addButton: {
	position: 'absolute',
	right: 0,
	zIndex: 1,
	},
	tabs: {
	flexGrow: 1,
	marginRight: theme.spacing(6), // Deja espacio para el botón
	},
	button: {
		margin: theme.spacing(1),
	},
}));


export const MulticropHeader = ({setOpenBackdrop,setLoadingUpdateData,loadingUpdateData}) => {
	const classes = useStyles();
	const {usuario, userTimezone, numberOfCans, currentMac} = useContext(UserContext);
	const { namesOfCrops,tabValue,setTabValue,actualNumberOfCrops,weeksOfCrops,daysOfCrops,placeOfCrops,crops,
		variantsOfCrop,phenoStages,
		setWeeksOfCrops,setDaysOfCrops, openConfigNewCrop,openEditWindow, setOpenEditWindow, 
		setOpenConfigNewCrop } = useContext(MultiCropContext);
	const [cropTab, setCropTab] = useState(0);
	const [totalCrops, setTotalCrops] = useState(["Default"])
	// const [openConfigNewCrop, setOpenConfigNewCrop] = useState(false);
	const [openDeleteWindow, setOpenDeleteWindow] = useState(false);
	
	const handleCloseConfigNewCrop = () => { setOpenConfigNewCrop(false) }
	const handleCloseEditCrop = () => { setOpenEditWindow(false) }
	const handleCloseDeleteCrop = () => { setOpenDeleteWindow(false) }
	const weekToShow = weeksOfCrops.length > 0 ? weeksOfCrops[tabValue] : "-";
	const dayToShow = daysOfCrops.length > 0 ? daysOfCrops[tabValue] : "-";
	const placeToShow = placeOfCrops.length > 0 ? placeOfCrops[tabValue] : "-";
	const cropToShow = crops.length > 0 ?  `${crops[tabValue]} ${variantsOfCrop[tabValue]}` : "-";
	
	function a11yProps(index) {
		return {
			id: `crop-${index}`,
			'aria-controls': `crop-${index}`,
		};
	}

	const handleCropTabChange = (event, newValue) => {
		setCropTab(newValue)
		setTabValue(newValue)
	}

	const handleUpdateData = async() => {
		setLoadingUpdateData(true);
		console.log("Entre al handleUpdateData")
		setOpenBackdrop(true)
	}
	useEffect(() => {
		try {
			const docRef = db.collection(usuario.username).doc('multiCrop')
			const fetchData = async () => {
				const doc = await docRef.get();
				if (doc.exists) {
					const cropsInfo = doc.data();
					const cropsIds = cropsInfo.listOfCrops;
					const userTimezone = usuario.timeZone;
					// const firebaseTimestampNow = firebase.firestore.Timestamp.fromDate(new Date(now.toISOString())); // Convertir a Timestamp
					const lastUpdated = moment.tz(cropsInfo.lastUpdated, userTimezone);
					// const lastUpdated = cropsInfo.lastUpdated.toDate();
					const now = moment.tz(userTimezone);
					const arrayOfDays = [];
					const arrayOfWeeks = [];
					const diffInDays = now.diff(lastUpdated, 'days'); // Diferencia en días
					if (diffInDays > 0) {
						// for (let index = 0; index < cropsInfo.totalCrops; index++) {
						// 	const addr = `${usuario.username}/multiCrop/${cropsIds[index]}`;
						// 	const documentData = db.collection(addr).doc('data')
						// 	const getData = await documentData.get();
						// 	const dataOfCrop = getData.data()
						// 	const currentDay = Number(dataOfCrop.growingDay)
						// 	const currentWeek = Number(dataOfCrop.growingWeek)
						// 	let newDay = currentDay + diffInDays;
						// 	let newWeek = currentWeek;
						// 	// Ciclo para ajustar días y semanas
						// 	while (newDay > 7) {
						// 		newWeek += 1; // Incrementamos la semana
						// 		newDay -= 7; // Restablecemos los días a 1 después de 7 días
						// 	}
						// 	// if (newDay > 7) {
						// 	// 	newWeek = currentWeek + 1;
						// 	// 	newDay = 1
						// 	// };
						// 	await documentData.update({ growingDay: String(newDay), growingWeek: String(newWeek)})
						// 	arrayOfDays.push(String(newDay))
						// 	arrayOfWeeks.push(String(newWeek))
						// }
						// await docRef.update({ lastUpdated: now.toISOString() })
						// setDaysOfCrops([...arrayOfDays])
						// setWeeksOfCrops([...arrayOfWeeks])
						const updatePromises = cropsIds.map(async (cropId, index) => {
							const addr = `${usuario.username}/multiCrop/${cropId}`;
							const documentData = db.collection(addr).doc('data');
							const getData = await documentData.get();
							const dataOfCrop = getData.data();
					
							const currentDay = Number(dataOfCrop.growingDay);
							const currentWeek = Number(dataOfCrop.growingWeek);
					
							let newDay = currentDay + diffInDays;
							let newWeek = currentWeek;
					
							// Ajustar días y semanas
							while (newDay > 7) {
								newWeek += 1; // Incrementamos la semana
								newDay -= 7; // Restablecemos los días a 1 después de 7 días
							}
					
							await documentData.update({
								growingDay: String(newDay),
								growingWeek: String(newWeek),
							});
					
							// Guardar resultados en los arreglos
							arrayOfDays[index] = String(newDay);
							arrayOfWeeks[index] = String(newWeek);
						});
					
						// Ejecutar todas las promesas en paralelo
						await Promise.all(updatePromises);
					
						// Actualizar `lastUpdated` después de que todas las promesas se completen
						await docRef.update({ lastUpdated: now.toISOString() });
					
						// Actualizar el estado
						setDaysOfCrops([...arrayOfDays]);
						setWeeksOfCrops([...arrayOfWeeks]);
					}
				}
			}

			// Configurar un intervalo para verificar periódicamente
			const intervalId = setInterval(async () => {
				await fetchData();
			}, 3600000); // 1 hora en milisegundos
			//}, 50000); // 5 minutos para el test
			fetchData();
			// Limpiar el intervalo al desmontar el componente
			return () => clearInterval(intervalId);
		} catch (error) {
			console.error("Error al actualizar el dia:",error)
		}
	}, [userTimezone,usuario.username])
	

  return (
	<div>
	<Grid container justifyContent="flex-start">
      <div className={classes.root}>
        <div className={classes.tabsContainer}>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleCropTabChange}
            className={classes.tabs}
          >
            {namesOfCrops?.map((cropName, index) => (
              <Tab label={cropName} {...a11yProps(index)} key={`crop${index}`} />
            ))}
          </Tabs>
		  <Tooltip title="Añadir Cultivo" arrow>
          <IconButton
            color="primary"
            aria-label="add new Crop"
            onClick={() => {setOpenConfigNewCrop(true)}}
            className={classes.addButton}
			disabled={actualNumberOfCrops === 15}
          >
            <AddCircle />
          </IconButton>
		  </Tooltip>
        </div>
      </div>
	  {/* <div style={{
        display: "flex",            // Para alinear elementos en la misma fila
        alignItems: "center",       // Centra verticalmente
        marginTop: "10px",
      	}}> */}
	  	{/* <Tooltip title="Editar Cultivo" arrow>
			<IconButton
				className={classes.button}
				onClick={() => { setOpenEditWindow(true)}}
				//startIcon={<Edit />}
				//endIcon={<Edit />}
			>
				<Edit />
			</IconButton>
		</Tooltip>

		{actualNumberOfCrops > 1 && (
			<Tooltip title="Eliminar Cultivo" arrow>
				<IconButton
					className={classes.button}
					onClick={() => { setOpenDeleteWindow(true)}}
					//startIcon={<Edit />}
					//endIcon={<Edit />}
					
				>
					<DeleteForever />
				</IconButton>
			</Tooltip>
		)} */}
		
		{/* <label className='h5' style={{ marginRight: "10px"}}>
			{`${phenoStages[tabValue]} | Semana: ${weekToShow}, Día: ${dayToShow} |`}
		</label>
		<label className='h5'>
			{`${cropToShow} | ${placeToShow} `}
		</label> */}
		
		{/* <div style={{ marginLeft: "auto" }}>
			<Tooltip title="Actualizar datos del dashboard" arrow>
			<Button
				variant="contained"
				color="primary"
				onClick={handleUpdateData}
				startIcon={<Autorenew />}
				disabled={loadingUpdateData}
			>
				
			</Button>
			</Tooltip>
		</div> */}
	  {/* </div> */}
	  
	  <NewCropConfigPopup 
	  openPopup={openConfigNewCrop} 
	  handleClosePopup={handleCloseConfigNewCrop} 
	  totalCrops={totalCrops}
	  setTotalCrops={setTotalCrops} 
	  setOpenConfigNewCrop={setOpenConfigNewCrop}
	  />

	  <EditCropConfig 
	  openPopup={openEditWindow}
	  handleClosePopup={handleCloseEditCrop}
	  />

	  <DeleteCropWindow 
	  openPopup={openDeleteWindow}
	  handleClosePopup={handleCloseDeleteCrop}
	  />
    </Grid>
	<div style={{
        display: "flex",            // Para alinear elementos en la misma fila
        alignItems: "center",       // Centra verticalmente
        marginTop: "10px",
      	}}>
	  	<Tooltip title="Editar Cultivo" arrow>
			<IconButton
				className={classes.button}
				onClick={() => { setOpenEditWindow(true)}}
				//startIcon={<Edit />}
				//endIcon={<Edit />}
			>
				<Edit />
			</IconButton>
		</Tooltip>

		{actualNumberOfCrops > 1 && (
			<Tooltip title="Eliminar Cultivo" arrow>
				<IconButton
					className={classes.button}
					onClick={() => { setOpenDeleteWindow(true)}}
					//startIcon={<Edit />}
					//endIcon={<Edit />}
					
				>
					<DeleteForever />
				</IconButton>
			</Tooltip>
		)}
		
		<label className='h5' style={{ marginRight: "10px"}}>
			{`${phenoStages[tabValue]} | Semana: ${weekToShow}, Día: ${dayToShow} |`}
		</label>
		<label className='h5'>
			{`${cropToShow} | ${placeToShow} `}
		</label>
		
		{(currentMac !== "" && numberOfCans.length !== 0) && (
			<div style={{ marginLeft: "auto" }}>
				<Tooltip title="Actualizar datos del dashboard" arrow>
				<Button
					variant="contained"
					color="primary"
					onClick={handleUpdateData}
					startIcon={<Autorenew />}
					disabled={loadingUpdateData}
				>
					{/* Texto u otro contenido dentro del botón */}
				</Button>
				</Tooltip>
			</div>
		)}
	  </div>
	  </div>
  )
}
